import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pdfStatus: '',
  prompt: '',
  loadingShow: null,
  chatshow: false,
};

const knowledgeSlice = createSlice({
  name: 'knowledge',
  initialState,
  reducers: {
    setPdfStatus: (state, action) => {
      state.pdfStatus = action.payload;
    },
    setPrompt: (state, action) => {
      state.prompt = action.payload;
    },
    setLoadingShow: (state, action) => {
      state.loadingShow = action.payload;
    },
    setChatshow: (state, action) => {
      state.chatshow = action.payload;
    },
    resetknowledgeState: () => initialState,
  },
});

export const { setPdfStatus, setPrompt, setLoadingShow, setChatshow, resetknowledgeState } = knowledgeSlice.actions;
export default knowledgeSlice.reducer;
