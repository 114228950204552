
import React, { useState } from 'react'
import SubHeader from '../Usable/SubHeader'
import SmartChat from './Chat'
import Files from '../Usable/Files'
import Preset from '../Usable/preset'



export default function MainNew() {
    const [activeButton, setActiveButton] = useState(null)

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName)
        console.log(activeButton)
    }
    return (
        <>
            <div className="  ">
                <div className="text-xl flex ">
                    <span>
                        <img className='h-6 ' src="/icons/playground.png" alt="playground logo" />
                    </span>
                    <h1 className="inline-block ml-2 font-bold">PlayGround</h1>
                </div>
                <div className="bg-white mt-3   rounded-t-lg">
                    {/* {here} */}
                    <SubHeader activeButton={activeButton} handleButtonClick={handleButtonClick} />
                    <div className="flex ">
                        <div className=" border-r w-80 h-full relative  mt-3 px-5">
                            <Files>
                                <Preset/>
                            </Files>
                        </div>

                        {/* Second column */}
                        <div style={{ height: '75vh' }} className="  bg-white my-2 px-4 w-4/5 ">
                            <div>
                                <SmartChat />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



// function FileUpload() {
//     const [presets, setPresets] = useState([
//         { input: 'Default Prompt 1', textField: 'Default Description 1', selected: false },
//         { input: 'Default Prompt 2', textField: 'Default Description 2', selected: false }
//     ])
//     const [showAddPresetModal, setShowAddPresetModal] = useState(false)

//     const handleAddPreset = (preset) => {
//         setPresets([...presets, preset])
//         setShowAddPresetModal(false)
//     }

//     const handleAddPresetCancel = () => {
//         setShowAddPresetModal(false)
//     }

//     const handleDeletePreset = (index) => {
//         const updatedPresets = [...presets]
//         updatedPresets.splice(index, 1)
//         setPresets(updatedPresets)
//     }

//     // Function to toggle preset selection
//     const togglePresetSelection = (index) => {
//         setPresets((prevPresets) => {
//             const updatedPresets = [...prevPresets]
//             updatedPresets[index] = {
//                 ...updatedPresets[index],
//                 selected: !updatedPresets[index].selected
//             }
//             return updatedPresets
//         })
//     }

//     return (
//         <>
           
//                 <Files />

//                 {/* Presets section */}
//                 <>
//                 {showAddPresetModal && <AddPresetModal onSave={handleAddPreset} onCancel={handleAddPresetCancel} />}

//                 <div className="flex mb-2 justify-between">
//                     <div className="flex items-center">
//                         <img src="/icons/down.png" alt="logo" className="w-6 h-6 object-cover" />
//                         <p>Presets</p>
//                     </div>
//                     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
//                         <button
//                             onClick={() => setShowAddPresetModal(true)}
//                             className="text-blue-600 rounded cursor-pointer"
//                         >
//                             + Add
//                         </button>
//                     </div>
//                 </div>

//                 <div className="flex max-h-40 h-40 overflow-y-auto flex-col py-2">
//                     {presets.map((preset, index) => (
//                         <button
//                             key={index}
//                             className={`my-1 flex p-2 text-sm rounded ${
//                                 preset.selected ? 'bg-blue-100' : 'bg-gray-100'
//                             }`}
//                             onClick={() => togglePresetSelection(index)}
//                         >
//                             <div className="flex flex-col items-start">
//                                 <p className="">{preset.input}</p>
//                                 <p className="text-xs text-gray-700">{preset.textField}</p>
//                             </div>
//                             {preset.selected && (
//                                 <button
//                                     className="flex items-center justify-center mx-auto"
//                                     onClick={() => handleDeletePreset(index)}
//                                 >
//                                     Delete
//                                 </button>
//                             )}
//                         </button>
//                     ))}
//                 </div>
//             </>
//         </>
//     )
// }

// function UploadPlus({ handleFileUpload, setShowModal }) {
//     return (
//         <>
//             <>
//                 <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
//                     <div className="relative w-auto my-6 mx-auto max-w-3xl">
//                         {/*content*/}
//                         <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
//                             {/*header*/}
//                             <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
//                                 <h5 className="text-gray-600">Upload +</h5>
//                                 <button
//                                     className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
//                                     onClick={() => setShowModal(false)}
//                                 >
//                                     <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
//                                         ×
//                                     </span>
//                                 </button>
//                             </div>
//                             {/*body*/}
//                             <div className="relative p-6 flex-auto flex justify-between">
//                                 {/* Left side - Drag and Drop */}
//                                 <div className="w-1/2 pr-2">
//                                     <h1 className="font-bold">Drag & Drop Area</h1>
//                                     <div className="border rounded-lg border-gray-400 h-32 p-6 my-3 flex items-center justify-center">
//                                         <p>Drag and drop your files here</p>
//                                     </div>
//                                     <input type="file" id="fileInput" className="hidden" onChange={handleFileUpload} />
//                                     <label htmlFor="fileInput" className="mt-2 block cursor-pointer text-blue-600">
//                                         Or click here to browse
//                                     </label>
//                                 </div>
//                                 {/* Right side - Dropdown Selector */}
//                                 <div className="w-1/2 pl-2">
//                                     <h1 className="font-bold">Dropdown Selector</h1>
//                                     <select className="mt-2 w-full border border-gray-300 rounded-md p-1">
//                                         {/* Populate dropdown options here */}
//                                         <option value="option1">Option 1</option>
//                                         <option value="option2">Option 2</option>
//                                         <option value="option3">Option 3</option>
//                                     </select>
//                                 </div>
//                             </div>
//                             {/*footer*/}
//                             <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
//                                 <button
//                                     className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
//                                     type="button"
//                                     onClick={() => setShowModal(false)}
//                                 >
//                                     Close
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
//             </>
//         </>
//     )
// }
// const RightDrawer = ({ isOpen, onClose }) => {
//     const [selectedButtons, setSelectedButtons] = useState([])

//     const toggleButtonSelection = (index) => {
//         if (selectedButtons.includes(index)) {
//             setSelectedButtons(selectedButtons.filter((item) => item !== index))
//         } else {
//             setSelectedButtons([...selectedButtons, index])
//         }
//     }

//     const buttons = Array.from({ length: 10 }, (_, index) => (
//         <button
//             key={index}
//             className={`flex w-full py-2 px-4 mb-2 justify-between mt-1  border rounded-md ${
//                 selectedButtons.includes(index) ? 'bg-blue-300 text-white' : 'bg-white text-black'
//             }`}
//             onClick={() => toggleButtonSelection(index)}
//         >
//             <p>Button {index + 1} </p>
//             {selectedButtons.includes(index) && <p className="text-blue-700">Selected</p>}
//         </button>
//     ))
//     return (
//         <>
//             {/* Dimmed background */}
//             {isOpen && (
//                 <div
//                     className="fixed top-0 left-0 w-full h-full bg-gray-900 opacity-50 z-50"
//                     onClick={() => {
//                         onClose(!isOpen)
//                     }}
//                 ></div>
//             )}

//             {/* Modal */}
//             {isOpen && (
//                 <div className="fixed top-0 right-0 h-full w-1/4 rounded z-50 outline-none focus:outline-none bg-white shadow-lg border-gray-300">
//                     <div className="p-4">
//                         <div className="flex justify-between border-b m-2">
//                             <h2 className="text-xl font-bold mb-4 py-3 ">Sections</h2>
//                             <button
//                                 className="rounded-md"
//                                 onClick={() => {
//                                     onClose(!isOpen)
//                                 }}
//                             >
//                                 X Close
//                             </button>
//                         </div>

//                         {buttons}
//                     </div>
//                 </div>
//             )}
//         </>
//     )
// }

// function AddPresetModal({ onSave, onCancel }) {
//     const [inputValue, setInputValue] = useState('')
//     const [textFieldValue, setTextFieldValue] = useState('')

//     const handleAddPreset = () => {
//         onSave({ input: inputValue, textField: textFieldValue })
//         setInputValue('')
//         setTextFieldValue('')
//     }

//     const handleAddPresetCancel = () => {
//         onCancel()
//         setInputValue('')
//         setTextFieldValue('')
//     }

//     return (
//         <>
//             <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
//                 <div className="relative w-auto my-6 mx-auto max-w-3xl">
//                     <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
//                         <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
//                             <h5 className="text-gray-600 m-2 border-b-2">Add Preset</h5>
//                             <button
//                                 className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
//                                 onClick={handleAddPresetCancel}
//                             >
//                                 <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
//                                     ×
//                                 </span>
//                             </button>
//                         </div>
//                         <div className="relative p-6">
//                             <input
//                                 type="text"
//                                 value={inputValue}
//                                 onChange={(e) => setInputValue(e.target.value)}
//                                 placeholder="Input Field"
//                                 className="w-full px-4 py-2 m-2 border rounded"
//                             />
//                             <textarea
//                                 value={textFieldValue}
//                                 onChange={(e) => setTextFieldValue(e.target.value)}
//                                 placeholder="Text Field"
//                                 className="w-full px-4 py-2 m-2 border rounded"
//                                 rows="4"
//                             />
//                         </div>
//                         <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
//                             <button
//                                 onClick={handleAddPresetCancel}
//                                 className="py-1 font-semibold px-5 m-2 border rounded-lg cursor-pointer"
//                             >
//                                 Cancel
//                             </button>
//                             <button
//                                 onClick={handleAddPreset}
//                                 className="py-1 px-5 font-semibold m-2 text-white bg-blue-600 rounded-lg cursor-pointer"
//                             >
//                                 Save
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
//         </>
//     )
// }
