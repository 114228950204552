import React, { useState } from 'react'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { DASHBOARD_SIDEBAR_LINKS, DASHBOARD_SIDEBAR_BOTTOM_LINKS } from '../../lib/constants'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveButton } from './../../app/features/navigationSlice'
const linkClass =
    'flex items-center gap-2 font-light  hover:bg-blue-100 hover:text-blue-700  hover:no-underline active:bg-blue-100   rounded-lg '
const linkClassDrop = 'flex items-center gap-1 font-light py-1 text-gray-400  hover:no-underline   '

export default function Sidebar() {
    const [collapse, setCollapse] = useState(false)
    return (
        <div
            style={{
                transition: 'width 2s ease-in-out' // Add transition property for width change
            }}
            onMouseEnter={() => setCollapse(!collapse)}
            onMouseLeave={() => setCollapse(!collapse)}
            className={`transition-width duration-700 ease-in-out ${
                collapse ? 'w-50' : ''
            }  py-4 px-2 flex flex-col  bg-white transition-all duration-1000`}
        >
            <div className={`flex items-center mx-1 gap-2 px-1 py-1`}>
                {collapse ? (
                    <div className="flex ">
                        <div>
                            <img className="h-12" src="/icons/logo.png" alt="silmaril" />
                        </div>
                        <div className="mt-auto mb-2 h">
                            <img  className="h-5 " src="/icons/silmaril.png" alt="silmaril" />
                        </div>
                    </div>
                ) : (
                    <div>
                        <img  className=" h-12"  src="/icons/logo.png" alt="silmaril" />
                    </div>
                )}
            </div>
            <div className=" flex flex-1 py-4 flex-col gap-0.5">
                {collapse
                    ? DASHBOARD_SIDEBAR_LINKS.map((link) => (
                          <SidebarLink key={link.key} link={link} droplist={link.dropable} />
                      ))
                    : DASHBOARD_SIDEBAR_LINKS.map((link) => <SidebarLinkCollapse key={link.key} link={link} />)}
            </div>

            <div className="flex flex-col gap-0.5 pt-2 border-t ">
                {collapse
                    ? DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => <SidebarLink key={link.key} link={link} />)
                    : DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => <SidebarLinkCollapse key={link.key} link={link} />)}
            </div>
        </div>
    )
}
function SidebarLink({ link }) {
    const { pathname } = useLocation()
    const [drop, setDrop] = useState(link.drop)
    const isActive = pathname.startsWith(link.path)
    const dispatch = useDispatch()

    const handleDropdownClick = (e) => {
        e.stopPropagation() // Stop the event from bubbling up to higher components
        e.preventDefault() // Prevent default link behavior
        setDrop(!drop)
    }

    const handleButtonClick = (e, buttonName) => {
        // e.preventDefault();
        // e.stopPropagation();
        dispatch(setActiveButton(buttonName))
    }

    return (
        <>
            <Link
                to={link.path}
                onClick={(e) => handleButtonClick('SmartChat')}
                className={classNames(
                    isActive ? 'bg-blue-100 rounded-lg text-blue-800' : 'text-black',
                    linkClass,
                    'py-2'
                )}
            >
                <span className="text-xs mx-3 text-gray-600">
                    <img className='h-6 w-6' src={link.icon} alt={`${link.label} logo`} />
                </span>
                {link.label}

                {link.drop && (
                    <div>
                        {drop ? (
                            <img
                                onClick={handleDropdownClick}
                                className="text-sm h-4 w-4 hover:cursor-pointer"
                                src="/icons/up.png"
                                alt="down arrow"
                            />
                        ) : (
                            <img
                                onClick={handleDropdownClick}
                                className="text-sm h-4 w-4 hover:cursor-pointer"
                                src="/icons/down.png"
                                alt="up arrow"
                            />
                        )}
                    </div>
                )}
            </Link>
            {drop && link.dropable && (
                <div className="pl-3">
                    {link.dropable.map((sublink) => (
                        <SidebarLinkDrop key={sublink.key} link={sublink} />
                    ))}
                </div>
            )}
        </>
    )
}

function SidebarLinkDrop({ link }) {
    const { pathname } = useLocation()
    const isActive = pathname.startsWith(link.path)

    return (
        <Link to={link.path} className={linkClassDrop}>
            <span className="text-xs px-3 text-gray-400">
                <img className='h-4 w-4' src={link.icon} alt={`${link.label} logo`} />
            </span>
            <p className="text-sm text-gray-400">{link.label}</p>
        </Link>
    )
}

function SidebarLinkCollapse({ link }) {
    const { pathname } = useLocation()

    // Check if the current pathname starts with the link.path
    const isActive = pathname.startsWith(link.path)

    return (
        <Link
            to={link.path}
            className={classNames(isActive ? 'p-2 bg-blue-100 rounded-lg' : 'text-black p-2', linkClass, 'py-2')}
        >
            <img className='h-6 w-6' src={link.icon} alt={`${link.label} logo`} />
        </Link>
    )
}
