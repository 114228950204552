import React, { useState } from 'react'

export default function SortableTh({ title, sortKey, setSortConfig, sortConfig }) {
    const [isActive, setIsActive] = useState('')

    const onSort = (key) => {
        let direction = 'ascending'
        setIsActive('up')
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending'
            setIsActive('down')
        } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = 'ascending'
            key = null
            setIsActive('')
        }

        setSortConfig({ key, direction })
    }

    return (
        <th scope="col" className=" cursor-pointer hover:border-gray-300 text-center  " onClick={() => onSort(sortKey)}>
            <div className="flex items-center ">
                <span className="">{title}</span>
                {/* {sortConfig.key === sortKey && (
              <span>{sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽'}</span>
            )} */}

                <div className="flex flex-col gap-0.5 mx-2">
                    <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.5 0L8.39712 4.5L0.602886 4.5L4.5 0Z"
                            fill="black"
                            fill-opacity={isActive === 'up' ? '0.5' : '0.1'}
                        />
                    </svg>

                    <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.5 5L0.602885 0.5L8.39711 0.500001L4.5 5Z"
                            fill="black"
                            fill-opacity={isActive === 'down' ? '0.5' : '0.1'}
                        />
                    </svg>
                </div>
            </div>
        </th>
    )
}
