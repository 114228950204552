import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { editText } from '../../../app/features/counterSlice';
import {
    addPreset as addSmartChatPreset,
    deletePreset as deleteSmartChatPreset,
    togglePresetSelection as toggleSmartChatPresetSelection,
    editPresetText as editSmartChatPresetText
} from '../../../app/features/smartchattaskslice';
import {
    addPreset as addKnowledgePreset,
    deletePreset as deleteKnowledgePreset,
    togglePresetSelection as toggleKnowledgePresetSelection,
    editPresetText as editKnowledgePresetText
} from '../../../app/features/knowledgetaskslice';
import {
    addPreset as addMirrorPreset,
    deletePreset as deleteMirrorPreset,
    togglePresetSelection as toggleMirrorPresetSelection,
    editPresetText as editMirrorPresetText
} from '../../../app/features/mirrortaskslice';
// import AddPresetModal from './AddPresetModal'; // Make sure the path is correct

function Presets() {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const handleChange = (text) => {
        dispatch(editText(text));
    };

    let slice;
    let addAction, deleteAction, toggleAction;
    const smartloc = useSelector((state) => state.smartChattask);
    const knowledgeloc = useSelector((state) => state.knowledgetask);
    const mirrorloc = useSelector((state) => state.mirrortask);

    if (pathname === '/playground/SmartChat' || pathname === '/playground' ) {
        slice = smartloc;
        addAction = addSmartChatPreset;
        deleteAction = deleteSmartChatPreset;
        toggleAction = toggleSmartChatPresetSelection;
    } 
    else if (pathname === '/playground/KnowledgeExtractor') {
        slice = knowledgeloc;
        addAction = addKnowledgePreset;
        deleteAction = deleteKnowledgePreset;
        toggleAction = toggleKnowledgePresetSelection;
    } else if (pathname === '/playground/Mirror') {
        slice = mirrorloc;
        addAction = addMirrorPreset;
        deleteAction = deleteMirrorPreset;
        toggleAction = toggleMirrorPresetSelection;
    }

    const [showAddPresetModal, setShowAddPresetModal] = useState(false);

    const handleAddPresetCancel = () => {
        setShowAddPresetModal(false);
    };

    const handleDeletePreset = (index) => {
        dispatch(deleteAction(index));
    };

    const handleAddPreset = (preset) => {
        dispatch(addAction(preset));
        setShowAddPresetModal(false);
    };

    const togglePresetSelection = (index) => {
        dispatch(toggleAction(index));
    };

    return (
        <>
            {showAddPresetModal && <AddPresetModal onSave={handleAddPreset} onCancel={handleAddPresetCancel} />}

            <div className="flex mb-2 justify-between">
                <div className="flex items-center">
                    <img src="/icons/down.png" alt="logo" className="w-4 h-4 object-cover" />
                    <p className="big">Tasks</p>
                </div>
                <button
                    onClick={() => setShowAddPresetModal(true)}
                    className="text-blue-600 small rounded cursor-pointer"
                >
                    + Add
                </button>
            </div>

            <div className="flex max-h-[12rem] h-[12rem] overflow-y-auto flex-col py-2">
                {slice && slice.presets && slice.presets.length > 0 ? (
                    slice.presets.map((preset, index) => (
                        <div
                            key={index}
                            className={`my-1 mr-2 flex items-center hover:bg-blue-100 p-2 text-sm rounded ${preset.selected ? 'bg-blue-100' : 'bg-gray-100'} group`}
                        >
                            <div
                                className="flex flex-col justify-start items-start w-full cursor-pointer"
                                onClick={() => {
                                    togglePresetSelection(index);
                                    handleChange(preset.textField);
                                }}
                            >
                                <p className="big">{preset.input}</p>
                                <p className="small text-gray-700">{preset.textField}</p>
                            </div>
                            <button
                                className="ml-auto flex items-center justify-center opacity-0 group-hover:opacity-100"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeletePreset(index);
                                }}
                            >
                                <img className="h-4 w-4" src="/icons/bin.png" alt="Bin" />
                            </button>
                        </div>
                    ))
                ) : (
                    <div className='small '>No presets available</div>
                )}
            </div>
        </>
    );
}

export default Presets;


// import React, { useState } from 'react';

function AddPresetModal({ onSave, onCancel }) {
    const [inputValue, setInputValue] = useState('');
    const [textFieldValue, setTextFieldValue] = useState('');

    const handleAddPreset = () => {
        if (!inputValue.trim() || !textFieldValue.trim()) {
            // alert("Both fields are required.");
            return;
        }
        onSave({ input: inputValue, textField: textFieldValue });
        setInputValue('');
        setTextFieldValue('');
    };

    const handleAddPresetCancel = () => {
        onCancel();
        setInputValue('');
        setTextFieldValue('');
    };

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto w-[26rem]">
                    <div className="border-0 rounded-lg shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex w-full items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h5 className="text-gray-600 m-2 big">Add Task</h5>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={handleAddPresetCancel}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        <div className="w-full p-6">
                            <p className="big ml-2">Title:</p>
                            <input
                                type="text"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                placeholder="Enter Title"
                                className="w-full small px-4 py-2 m-2 border rounded"
                            />
                            <p className="big ml-2">Instruction:</p>
                            <textarea
                                value={textFieldValue}
                                onChange={(e) => setTextFieldValue(e.target.value)}
                                placeholder="Enter Instruction"
                                className="w-full small px-4 py-2 m-2 border rounded"
                                rows="4"
                            />
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                            <button
                                onClick={handleAddPresetCancel}
                                className="py-1 font-semibold px-5 m-2 border rounded big cursor-pointer"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleAddPreset}
                                className="py-1 px-5 font-semibold m-2 text-white bg-blue-600 rounded big cursor-pointer"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

// export default AddPresetModal;
