// import React, { useEffect, useState } from 'react';
// import Cookies from 'js-cookie';

// // Cookie management functions
// export const setCookie = (name, value) => {
//   Cookies.set(name, value);
// };

// export const getCookie = (name) => {
//   return Cookies.get(name);
// };

// export const removeCookie = (name) => {
//   Cookies.remove(name);
// };

// const App = () => {
//   const [user, setUser] = useState(getCookie('user'));
//   const [list, setList] = useState(() => {
//     const initialList = getCookie('list');
//     return initialList ? JSON.parse(initialList) : [['item1a', 'item1b'], ['item2a', 'item2b']];
//   });

//   useEffect(() => {
//     console.log('User:', user);
//     console.log('List:', list);
//   }, [user, list]);

//   const handleUserButtonClick = () => {
//     setCookie('user', 'John Doe');
//     setUser(getCookie('user'));
//   };

//   const handleAddItem = (item1, item2) => {
//     const newList = [...list, [item1, item2]];
//     setCookie('list', JSON.stringify(newList));
//     setList(newList);
//   };

//   const handleRemoveItem = (index) => {
//     const newList = list.filter((_, i) => i !== index);
//     setCookie('list', JSON.stringify(newList));
//     setList(newList);
//   };

//   return (
//     <div>
      
//       {user && <p>Hello, {user}!</p>}
//       <button onClick={handleUserButtonClick}>Set User Cookie</button>

//       <h2>List</h2>
//       <ul>
//         {list.map((sublist, index) => (
//           <li key={index}>
//             {sublist[0]} and {sublist[1]} 
//             <button onClick={() => handleRemoveItem(index)}>Remove</button>
//           </li>
//         ))}
//       </ul>
//       <button onClick={() => handleAddItem(`item${list.length + 1}a`, `item${list.length + 1}b`)}>Add Item</button>
//     </div>
//   );
// };

// export default App;


import React from 'react'

function Header() {
  return (
    <div><></></div>
  )
}

export default Header
