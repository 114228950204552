import React, { useState, useRef, useEffect } from 'react'
import SubHeader from '../Usable/SubHeader'
import Files from '../Usable/Files'
import Preset from '../Usable/preset'
import { setShowMessageMirror } from '../../../app/features/fileSlice'
import {
    setIsGenerated,
    setIsLoading,
    setStop,
    setIsListVisible,
    setTasks,
    addTask,
    removeTask
} from '../../../app/features/mirrorSlice' // Adjust the path as needed

import { useSelector, useDispatch } from 'react-redux'

export default function Main() {
    const [activeButton, setActiveButton] = useState(null)

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName)
    }

    return (
        <>
            <div className="  ">
                <div className="text-xl flex ">
                    <span>
                        <img className="h-6 " src="/icons/playground.png" alt="playground logo" />
                    </span>
                    <h1 className="inline-block ml-2 font-bold">PlayGround</h1>
                </div>
                <div className="bg-white mt-3 h-full  rounded-t-lg">
                    {/* {here} */}
                    <SubHeader activeButton={activeButton} handleButtonClick={handleButtonClick} />
                    <div className="flex ">
                        <div className=" border-r w-80 h-full relative  mt-3 px-5">
                            <Files>
                                <Preset />
                            </Files>
                        </div>

                        {/* Second column */}
                        <div style={{ height: '78vh' }} className="  my-2 px-20 w-4/5 ">
                            <div>
                                <TaskManager />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const TaskManager = () => {
    const dispatch = useDispatch();
    const { tasks, isListVisible, isGenerated, isLoading, stop } = useSelector((state) => state.mirror)
    const qntyFilesRedux = useSelector((state) => state.files.qntyFiles)

    const inputValueRef = useRef(null) // Initialize with null

    const taskListRef = useRef(null)
    const lastTaskRef = useRef(null)

    const handleAddTask = () => {
        const inputValue = inputValueRef.current.value.trim()
        if (inputValue !== '') {
            dispatch(addTask(inputValue)) // Dispatch action to add task
            inputValueRef.current.value = '' // Clear input
            dispatch(setIsListVisible(true)) // Set list visible
        }
    }
    const [inputValue, setInputValue] = useState('')

    const handleRemoveTask = (index) => {
        dispatch(removeTask(index))
        
    }

    const handleToggleList = () => {
        if (tasks.length > 0) {
            dispatch(setIsListVisible(!isListVisible)) // Dispatch action to toggle list visibility
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleAddTask()
            setInputValue('')
        }
    }

    const handleShowMessageMirror = (x) => {
        dispatch(setShowMessageMirror(x))
    }

    const handleGenerate = () => {
        dispatch(setIsLoading(false))
        dispatch(setIsGenerated(false))
        dispatch(setStop(false))

        if (qntyFilesRedux === 2) {
            handleShowMessageMirror(false)
            dispatch(setIsLoading(true))
            dispatch(setIsGenerated(false))
            dispatch(setIsListVisible(false)) // Dispatch action to hide the list
            setTimeout(() => {
                setInputValue('')
                dispatch(setIsGenerated(true))
                dispatch(setIsLoading(false))
            }, 3000)
        } else {
            handleShowMessageMirror(true)
        }
    }

    const handleStop = () => {
        dispatch(setStop(true))
        dispatch(setIsLoading(false))
    }
    const { presets } = useSelector((state) => state.mirrortask);
    // const [syncWithRedux, setSyncWithRedux] = useState(true);
    useEffect(() => {
        
            const selectedPreset = presets.find((preset) => preset.selected);
            if (selectedPreset) {
              setInputValue(selectedPreset.textField);
            } else {
              setInputValue('How can I help you?');
            }
        
    }, [presets]);
    
    useEffect(() => {
        
        if (isListVisible && tasks.length > 0) {
            lastTaskRef.current?.scrollIntoView({ behavior: 'smooth' })
        };
        if (tasks.length === 0) {
            dispatch(setIsListVisible(false)) 
        };
    }, [tasks, isListVisible])

    return (
        <>
            <div className="flex w-full overflow-y-auto  pt-5  justify-center" style={{ height: '46.5vh' }}>
                {isLoading && !stop && (
                    <div className="flex flex-col items-center justify-center">
                        <div>
                            <div
                                className="inline-block text-black h-10 w-10 mx-1 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status"
                            >
                                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                    Loading...
                                </span>
                            </div>
                        </div>
                        <h1 className="font-bold text-lg"> Processing Data </h1>
                        <h4 className="text-gray-400">Please be patient as we are generating the Data.</h4>
                        <h4 className="text-gray-400">This may take some time</h4>
                    </div>
                )}
                {isGenerated && !stop && <Generated />}
            </div>

            <div style={{ height: '20vh' }} className="flex mt-10 flex-column items-center justify-center">
                <div className={`w-5/6 ${isListVisible ? 'border bg-white rounded-lg' : 'no-border'}`}>
                    <div className="flex flex-col items-left m-1">
                        <div className="my-1 w-4/5 " style={{ height: '100px', overflowY: 'auto' }}>
                            {isListVisible && (
                                <div ref={taskListRef} className="border-gray-300 w-full rounded px-4 py-1 mb-4">
                                    {tasks.map((task, index) => (
                                        <div
                                            className="flex w-full small justify-between items-center flex-wrap rounded-md bg-gray-50 p-2 mb-1"
                                            key={index}
                                            ref={index === tasks.length - 1 ? lastTaskRef : null}
                                        >
                                           
                                            <div>
                                                <span className="flex  flex-wrap cursor-pointer">
                                                    <p>{task}</p>
                                                </span>
                                            </div>
                                            <div className=''>
                                                <button
                                                    onClick={() => handleRemoveTask(index)}
                                                    className="text-gray-700"
                                                >
                                                    <img className="h-3 w-3" src="/icons/x.png" alt="logo" />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div
                            className={`justify-center  px-2 items-center w-full flex rounded-lg ${
                                isListVisible ? 'no-border' : 'border'
                            }`}
                        >
                            <input
                                ref={inputValueRef} // Assign ref to input element
                                className="w-full small border m-2 rounded-md px-5 py-2 focus:outline-none active:no-border"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyPress={handleKeyPress}
                                placeholder="How can I help you?"
                            />
                            <button className="h-4 w-4 m-1 mr-2" onClick={handleToggleList}>
                                <img src="/icons/full.png" className="bg-white" alt="toggle list visibility" />
                            </button>
                            <div className="flex items-center justify-between">
                                {isLoading ? (
                                    <button
                                        className="flex text-blue-700 bg-gray-100 justify-center items-center py-2 px-1 rounded-md  "
                                        onClick={handleStop}
                                    >
                                        <div className="mx-[33px] my-[2px] flex align-center justify-center  ">
                                            <img className="h-4 w-4 mr-1" src="/icons/blueStar.png" alt="stop" />
                                            <p className="mr-2 big">Stop</p>
                                        </div>
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleGenerate}
                                        style={{
                                            backgroundImage:
                                                'linear-gradient(to right, rgba(123, 97, 255, 1), rgba(82, 60, 196, 1))'
                                        }}
                                        className=" py-2 px-1 rounded-md"
                                    >
                                        <div className="mx-5 my-[2px] text-white flex align-center justify-center ">
                                            <img className="h-4 w-4 mr-1" src="/icons/whiteStar.png" alt="Generate" />
                                            <p className="ml-1 big">Generate</p>
                                        </div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// using usestate
// const [isGenerated, setIsGenerated] = useState(false)
// const [isLoading, setIsLoading] = useState(false)
// const [stop, setStop] = useState(false)

// const handleGenerate = () => {
//     setIsLoading(false)
//     setIsGenerated(false)
//     setStop(false)
//     if (qntyFilesRedux === 2) {

//         handleShowMessageMirror(false)
//         setIsLoading(true)
//         setIsGenerated(false)
//         setIsListVisible(false)
//         setTimeout(() => {
//             setIsGenerated(true)
//             setIsLoading(false)
//         }, 3000)
//     } else {
//         handleShowMessageMirror(true)
//     }
// }

// const handleStop = () => {
//     setStop(true)
//     setIsLoading(false)

// }

const Generated = () => {
    const toggleVisibility = (id) => {
        const section = document.getElementById(id)
        const buttonIcon = document.getElementById(id + 'Button') // Getting the button icon
        if (section.style.display === 'none') {
            section.style.display = 'block'
            buttonIcon.src = '/icons/up.png' // Show "up" icon when section is displayed
        } else {
            section.style.display = 'none'
            buttonIcon.src = '/icons/down.png' // Show "down" icon when section is hidden
        }
    }

    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                // alert('Text copied to clipboard')
            })
            .catch((err) => {
                console.error('Failed to copy: ', err)
            })
    }

    return (
        <div className="small text-gray-500 w-full h-[96]  ">
            <div className="mr-10  w-full mx-22 px-5 mb-2 ">
                <div className="px-2 py-1 overflow-auto border-2  rounded-lg bg-blue-50">
                    <div className="flex justify-between ">
                        <div className="flex items-center gap-1">
                            <h1 className="small font-semibold">Specific key points</h1>
                        </div>
                        <div className="flex items-center gap-1">
                            <button className="m-1" onClick={() => copyToClipboard('Specific key points content')}>
                                <img className="w-5 h-5 " src="/icons/copy.png" alt="copy" />
                            </button>
                            <button className="m-1" onClick={() => toggleVisibility('keyPoints')}>
                                <img className="w-5 h-5 " src="/icons/up.png" alt="toggle" id="keyPointsButton" />
                            </button>
                        </div>
                    </div>
                    <div id="keyPoints" className="small">
                        <p id="" className="pb-2 small">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum iste culpa perferendis est
                            quisquam sunt natus nisi minus. Autem, optio laudantium velit assumenda aperiam quas vel non
                            eos eius ut. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum iste culpa
                            perferendis est quisquam sunt natus nisi minus. Autem, optio laudantium velit assumenda
                            aperiam quas vel non eos eius ut. Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                            Dolorum iste
                        </p>

                        <p id="" className="small">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum iste culpa perferendis est
                            quisquam sunt natus nisi minus. Autem, optio laudantium velit assumenda aperiam quas vel non
                            eos eius ut. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum iste culpa
                            perferendis est quisquam sunt natus nisi minus. Autem, optio laudantium velit assumenda
                            aperiam quas vel non eos eius ut. Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                            Dolorum iste ipsum dolor sit amet consectetur adipisicing elit. Dolorum iste culpa
                            perferendis est quisquam sunt natus nisi minus. Autem, optio laudantium velit assumenda
                            aperiam quas vel non eos eius ut.
                        </p>
                    </div>
                </div>
            </div>

            <div className="mr-10 overflow-y-auto w-full px-5 my-1  overflow-auto small">
                <div className="px-2 py-1 overflow-auto border rounded-lg bg-blue-50">
                    <div className="flex justify-between ">
                        <div className="flex items-center gap-1">
                            <h1 className="small font-semibold">Reports</h1>
                        </div>
                        <div className="flex items-center gap-1">
                            <button
                                className="m-1"
                                onClick={() =>
                                    copyToClipboard(
                                        ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis eligendi pariatur odit ducimus nisi dolores voluptas deleniti consequuntur accusantium blanditiis, corporis nostrum quaedantium quam quaerat doloribus quisquam magni. Minima'
                                    )
                                }
                            >
                                <img className="w-5 h-5 " src="/icons/copy.png" alt="copy" />
                            </button>
                            <button className="m-1" onClick={() => toggleVisibility('reports')}>
                                <img className="w-5 h-5 " src="/icons/up.png" alt="toggle" id="reportsButton" />
                            </button>
                        </div>
                    </div>
                    <p className="small" id="reports">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis eligendi pariatur odit ducimus
                        nisi dolores voluptas deleniti consequuntur accusantium blanditiis, corporis nostrum quae
                        laudantium quam quaerat doloribus quisquam magni. Minima?
                    </p>
                </div>
            </div>

            <div className="mr-10 overflow-y-auto w-full px-5 mt-2 my-1 overflow-auto ">
                <div className="px-2 py-1 overflow-auto border rounded-lg bg-blue-50">
                    <div className="flex justify-between ">
                        <div className="flex items-center gap-1">
                            <p className="small font-semibold">Expense sheet</p>
                        </div>
                        <div className="flex items-center gap-1">
                            <button
                                className="m-1"
                                onClick={() =>
                                    copyToClipboard(
                                        ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis eligendi pariatur odit ducimus nisi dolores voluptas deleniti consequuntur accusantium blanditiis, corporis nostrum quaedantium quam quaerat doloribus quisquam magni. Minima'
                                    )
                                }
                            >
                                <img className="w-5 h-5 " src="/icons/copy.png" alt="copy" />
                            </button>
                            <button className="m-1" onClick={() => toggleVisibility('expenseSheet')}>
                                <img className="w-5 h-5 " src="/icons/up.png" alt="toggle" id="expenseSheetButton" />
                            </button>
                        </div>
                    </div>
                    <p className="small" id="expenseSheet">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim velit earum hic sequi repellendus
                        minus aliquid eveniet assumenda porro veritatis nobis reprehenderit beatae tenetur odio
                        inventore voluptates suscipit, vitae doloribus?
                    </p>
                </div>
            </div>
        </div>
    )
}

// function FileUpload() {
//     const [presets, setPresets] = useState([
//         { input: 'Default Prompt 1', textField: 'Default Description 1', selected: false },
//         { input: 'Default Prompt 2', textField: 'Default Description 2', selected: false }
//     ])
//     const [showAddPresetModal, setShowAddPresetModal] = useState(false)

//     const handleAddPreset = (preset) => {
//         setPresets([...presets, preset])
//         setShowAddPresetModal(false)
//     }

//     const handleAddPresetCancel = () => {
//         setShowAddPresetModal(false)
//     }

//     const handleDeletePreset = (index) => {
//         const updatedPresets = [...presets]
//         updatedPresets.splice(index, 1)
//         setPresets(updatedPresets)
//     }

//     // Function to toggle preset selection
//     const togglePresetSelection = (index) => {
//         setPresets((prevPresets) => {
//             const updatedPresets = [...prevPresets]
//             updatedPresets[index] = {
//                 ...updatedPresets[index],
//                 selected: !updatedPresets[index].selected
//             }
//             return updatedPresets
//         })
//     }
//     const [showModal, setShowModal] = useState(false)
//     // const [activeButton, setActiveButton] = useState(false)

//     const handleClick = () => {
//         setShowModal(true)
//         // setActiveButton(true)
//     }

//     const [data, setData] = useState([
//         'Digital Market Acts-01.pdf',
//         'Digital Market Acts-02.pdf',
//         'Digital Market Acts-03.pdf'
//     ])

//     const handleFileUpload = (event) => {
//         const file = event.target.files[0]
//         const fileName = file.name

//         if (!data.includes(fileName)) {
//             setData([...data, fileName])
//         }

//         console.log('Uploaded file:', file)
//     }
//     const [selectedFiles, setSelectedFiles] = useState([])

//     const toggleFileSelection = (index) => {
//         setSelectedFiles((prevSelectedFiles) => {
//             if (prevSelectedFiles.includes(index)) {
//                 return prevSelectedFiles.filter((item) => item !== index)
//             } else {
//                 return [...prevSelectedFiles, index]
//             }
//         })
//     }

//     return (
//         <>
//             <>
//                 <Files />

//                 {/* Presets section */}

//                 {showAddPresetModal && <AddPresetModal onSave={handleAddPreset} onCancel={handleAddPresetCancel} />}

//                 <div className="flex mb-2 justify-between">
//                     <div className="flex items-center">
//                         <img src="/icons/down.png" alt="logo" className="w-6 h-6 object-cover" />
//                         <p>Presets</p>
//                     </div>
//                     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
//                         <button
//                             onClick={() => setShowAddPresetModal(true)}
//                             className="text-blue-600 rounded cursor-pointer"
//                         >
//                             + Add
//                         </button>
//                     </div>
//                 </div>

//                 <div className="flex max-h-40 h-40 overflow-y-auto flex-col py-2">
//                     {presets.map((preset, index) => (
//                         <button
//                             key={index}
//                             className={`my-1 flex p-2 text-sm rounded ${
//                                 preset.selected ? 'bg-blue-100' : 'bg-gray-100'
//                             }`}
//                             onClick={() => togglePresetSelection(index)}
//                         >
//                             <div className="flex flex-col items-start">
//                                 <p className="">{preset.input}</p>
//                                 <p className="text-xs text-gray-700">{preset.textField}</p>
//                             </div>
//                             {preset.selected && (
//                                 <button
//                                     className="flex items-center justify-center mx-auto"
//                                     onClick={() => handleDeletePreset(index)}
//                                 >
//                                     Delete
//                                 </button>
//                             )}
//                         </button>
//                     ))}
//                 </div>
//             </>
//         </>
//     )
// }

// function UploadPlus({ handleFileUpload, setShowModal }) {
//     return (
//         <>
//             <>
//                 <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
//                     <div className="relative w-auto my-6 mx-auto max-w-3xl">
//                         {/*content*/}
//                         <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
//                             {/*header*/}
//                             <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
//                                 <h5 className="text-gray-600">Upload +</h5>
//                                 <button
//                                     className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
//                                     onClick={() => setShowModal(false)}
//                                 >
//                                     <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
//                                         ×
//                                     </span>
//                                 </button>
//                             </div>
//                             {/*body*/}
//                             <div className="relative p-6 flex-auto flex justify-between">
//                                 {/* Left side - Drag and Drop */}
//                                 <div className="w-1/2 pr-2">
//                                     <h1 className="font-bold">Drag & Drop Area</h1>
//                                     <div className="border rounded-lg border-gray-400 h-32 p-6 my-3 flex items-center justify-center">
//                                         <p>Drag and drop your files here</p>
//                                     </div>
//                                     <input type="file" id="fileInput" className="hidden" onChange={handleFileUpload} />
//                                     <label htmlFor="fileInput" className="mt-2 block cursor-pointer text-blue-600">
//                                         Or click here to browse
//                                     </label>
//                                 </div>
//                                 {/* Right side - Dropdown Selector */}
//                                 <div className="w-1/2 pl-2">
//                                     <h1 className="font-bold">Dropdown Selector</h1>
//                                     <select className="mt-2 w-full border border-gray-300 rounded-md p-1">
//                                         {/* Populate dropdown options here */}
//                                         <option value="option1">Option 1</option>
//                                         <option value="option2">Option 2</option>
//                                         <option value="option3">Option 3</option>
//                                     </select>
//                                 </div>
//                             </div>
//                             {/*footer*/}
//                             <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
//                                 <button
//                                     className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
//                                     type="button"
//                                     onClick={() => setShowModal(false)}
//                                 >
//                                     Close
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
//             </>
//         </>
//     )
// }
// const RightDrawer = ({ isOpen, onClose }) => {
//     const [selectedButtons, setSelectedButtons] = useState([])

//     const toggleButtonSelection = (index) => {
//         if (selectedButtons.includes(index)) {
//             setSelectedButtons(selectedButtons.filter((item) => item !== index))
//         } else {
//             setSelectedButtons([...selectedButtons, index])
//         }
//     }

//     const buttons = Array.from({ length: 10 }, (_, index) => (
//         <button
//             key={index}
//             className={`flex w-full py-2 px-4 mb-2 justify-between mt-1  border rounded-md ${
//                 selectedButtons.includes(index) ? 'bg-blue-300 text-white' : 'bg-white text-black'
//             }`}
//             onClick={() => toggleButtonSelection(index)}
//         >
//             <p>Button {index + 1} </p>
//             {selectedButtons.includes(index) && <p className="text-blue-700">Selected</p>}
//         </button>
//     ))
//     return (
//         <>
//             {/* Dimmed background */}
//             {isOpen && (
//                 <div
//                     className="fixed top-0 left-0 w-full h-full bg-gray-900 opacity-50 z-50"
//                     onClick={() => {
//                         onClose(!isOpen)
//                     }}
//                 ></div>
//             )}

//             {/* Modal */}
//             {isOpen && (
//                 <div className="fixed top-0 right-0 h-full w-1/4 rounded z-50 outline-none focus:outline-none bg-white shadow-lg border-gray-300">
//                     <div className="p-4">
//                         <div className="flex justify-between border-b m-2">
//                             <h2 className="text-xl font-bold mb-4 py-3 ">Sections</h2>
//                             <button
//                                 className="rounded-md"
//                                 onClick={() => {
//                                     onClose(!isOpen)
//                                 }}
//                             >
//                                 X Close
//                             </button>
//                         </div>

//                         {buttons}
//                     </div>
//                 </div>
//             )}
//         </>
//     )
// }

// function AddPresetModal({ onSave, onCancel }) {
//     const [inputValue, setInputValue] = useState('')
//     const [textFieldValue, setTextFieldValue] = useState('')

//     const handleAddPreset = () => {
//         onSave({ input: inputValue, textField: textFieldValue })
//         setInputValue('')
//         setTextFieldValue('')
//     }

//     const handleAddPresetCancel = () => {
//         onCancel()
//         setInputValue('')
//         setTextFieldValue('')
//     }

//     return (
//         <>
//             <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
//                 <div className="relative w-auto my-6 mx-auto max-w-3xl">
//                     <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
//                         <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
//                             <h5 className="text-gray-600 m-2 border-b-2">Add Preset</h5>
//                             <button
//                                 className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
//                                 onClick={handleAddPresetCancel}
//                             >
//                                 <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
//                                     ×
//                                 </span>
//                             </button>
//                         </div>
//                         <div className="relative p-6">
//                             <input
//                                 type="text"
//                                 value={inputValue}
//                                 onChange={(e) => setInputValue(e.target.value)}
//                                 placeholder="Input Field"
//                                 className="w-full px-4 py-2 m-2 border rounded"
//                             />
//                             <textarea
//                                 value={textFieldValue}
//                                 onChange={(e) => setTextFieldValue(e.target.value)}
//                                 placeholder="Text Field"
//                                 className="w-full px-4 py-2 m-2 border rounded"
//                                 rows="4"
//                             />
//                         </div>
//                         <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
//                             <button
//                                 onClick={handleAddPresetCancel}
//                                 className="py-1 font-semibold px-5 m-2 border rounded-lg cursor-pointer"
//                             >
//                                 Cancel
//                             </button>
//                             <button
//                                 onClick={handleAddPreset}
//                                 className="py-1 px-5 font-semibold m-2 text-white bg-blue-600 rounded-lg cursor-pointer"
//                             >
//                                 Save
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
//         </>
//     )
// }

// {/* {activeButton === 'Mirror' && qntyFilesRedux === 2 ? (
//                                             <button
//                                                 style={{
//                                                     backgroundImage:
//                                                         'linear-gradient(to right, rgba(123, 97, 255, 1), rgba(82, 60, 196, 1))'
//                                                 }}
//                                                 className=" py-2 px-1 rounded-md"
//                                             >
//                                                 <div
//                                                     onClick={() => {
//                                                         handleGenerate()
//                                                         setIsListVisible(false)
//                                                     }}
//                                                     className="mx-5 my-[2px] text-white flex align-center justify-center "
//                                                 >
//                                                     <img
//                                                         className="h-4 w-4 mr-1"
//                                                         src="/icons/whiteStar.png"
//                                                         alt="Generate"
//                                                     />
//                                                     <p className="ml-1 big">Generate</p>
//                                                 </div>
//                                             </button>
//                                         ) : (
//                                             <button className=" bg-gray-50 py-2 px-1 rounded-md">
//                                                 <div className="mx-5 my-[2px] text-gray-700  flex align-center justify-center ">
//                                                     <img
//                                                         className="h-4 w-4 mr-1"
//                                                         src="/icons/grayStar.png"
//                                                         alt="Generate"
//                                                     />
//                                                     <p className="ml-1 big  text-gray-400">Generate</p>
//                                                 </div>
//                                             </button>
//                                         )} */}
