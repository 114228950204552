export const DASHBOARD_SIDEBAR_LINKS = [
    {
        key: 'Play ground',
        label: 'Play ground',
        path: '/playground',
        icon: '/icons/chart.png',
        sub : 'SmartChat',
        drop: true,
        dropable: [
            { key: 'bookmark', label: 'Bookmark', path: '/playground/bookmark', icon: '/icons/bookmarklight.png' },
            { key: 'logs', label: 'Logs', path: '/playground/logs', icon: '/icons/loglight.png' },
            { key: 'files', label: 'Files', path: '/playground/files', icon: '/icons/folderlight.png' }
        ]
    },

    {
        key: 'Country Risk Index',
        label: 'Country Risk Index',
        path: '/Country_Risk_Index',
        icon: '/icons/index.png',
        drop: true
    },
    {
        key: 'Trends & Higlights',
        label: 'Trends & Higlights',
        path: '/Trends_&_Higlights',
        icon: '/icons/trend.png'
    },
    {
        key: 'Economic',
        label: 'Economic',
        path: '/Economic',
        icon: '/icons/economic.png',
        drop: true
    },
    {
        key: 'Supply Chain Risk',
        label: 'Supply Chain Risk',
        path: '/Supply_Chain_Risk',
        icon: '/icons/gas-station.png',
        drop: true
    },
    {
        key: 'Social',
        label: 'Social',
        path: '/Social',
        icon: '/icons/social.png'
    },
    {
        key: 'Regulatory risk',
        label: 'Regulatory risk',
        path: '/Regulatory_risk',
        icon: '/icons/courthouse.png',
        drop: true
    },
    {
        key: 'Analysis & Overview',
        label: 'Analysis & Overview',
        path: '/Analysis_&_Overview',
        icon: '/icons/Analysis.png'
    }
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
    {
        key: 'Watchdog',
        label: 'Watchdog',
        path: '/Watchdog',
        icon: '/icons/alarm.png'
    }
]
export const WATCH_DOGS_DROP = [
    {
        key: 'General alarms',
        label: 'General alarms',
        path: '/General alarms',
        icon: '/icons/people_light'
    },
    {
        key: 'Custom alarms',
        label: 'Custom alarms',
        path: '/Custom alarms',
        icon: '/icons/people_light'
    },
    {
        key: 'Portfolio',
        label: 'Portfolio',
        path: '/Portfolio',
        icon: '/icons/people_light'
    }
]
