import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './components/shared/Layout'
import KnowledgeExtractor from './components/shared/KnowledgeExtractor/MainNew.jsx'
import SmartChat from './components/shared/SmartChat/MainNew.jsx'
import Mirror from './components/shared/Mirror/MainNew.jsx'
import Files from './components/shared/Files/files.jsx'
import Bookmark from './components/shared/Bookmark/Bookmark.jsx'
import Logs from './components/shared/Logs/Logs.jsx'
import Login from './components/shared/Login/Login.jsx'
function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Layout />}>
                  
                    <Route path="/playground" element={<SmartChat />} />
                    <Route path="/playground/KnowledgeExtractor" element={<KnowledgeExtractor />} />
                    <Route path="/playground/SmartChat" element={<SmartChat />} />
                    <Route path="/playground/Mirror" element={<Mirror />} />
                    <Route path="/playground/bookmark" element={<Bookmark />} />
                    <Route path="/playground/logs" element={<Logs />} />
                    <Route path="/playground/files" element={<Files />} />
                </Route>
            </Routes>
        </Router>
    )
}

export default App
