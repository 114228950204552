import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetknowledgeState } from '../../../app/features/knowledgeSlice'
import { resetmirrorState } from '../../../app/features/mirrorSlice'
import { resetsmartchatState } from '../../../app/features/smartChatSlice'
import { useLocation } from 'react-router-dom'

const CleanData = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [showConfirmation, setShowConfirmation] = useState(false)

    const handleReset = () => {
        const { pathname } = location

        // Use if-else or switch statements based on pathname
        if (pathname.includes('/KnowledgeExtractor')) {
            dispatch(resetknowledgeState())
        } else if (pathname.includes('/Mirror')) {
            dispatch(resetmirrorState())
        } else if (pathname.includes('/SmartChat')  || pathname === '/playground') {
            dispatch(resetsmartchatState())
        } else {
            // Handle other cases or default action
            console.log('No reset action for this path')
        }

        // Close the confirmation modal after reset
        setShowConfirmation(false)
    }

    const handleCancel = () => {
        setShowConfirmation(false)
    }

    const showConfirmationModal = () => {
        setShowConfirmation(true)
    }

    return (
        <>
            <button
                onClick={showConfirmationModal}
                className="bg-white border m-2 hover:bg-blue-100 py-2 px-4 rounded flex items-center"
            >
                <img className="h-5 w-5 mr-2" src="/icons/eraser.png" alt="logo" />
                <span>
                    <p className="big">Reset</p>
                </span>
            </button>

            {/* Confirmation Modal */}
            {showConfirmation && (
                <div className="fixed top-0 left-0 right-0 bottom-0 z-20 bg-gray-700 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-8 rounded-lg">
                        <p className="text-lg mb-4 p-6">Pressing Continue will clear the work area</p>
                        <div className="flex justify-center pt-4">
                            <button
                                onClick={handleCancel}
                                className="py-1 font-semibold px-5 m-2 border rounded big cursor-pointer"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleReset}
                                className="py-1 px-5 font-semibold m-2 text-white bg-blue-600 rounded big cursor-pointer"
                            >
                                 Continue
                            </button>
                            
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CleanData
