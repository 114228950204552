import React, { useState } from 'react'

function UploadPlus({ handleFileUpload, setShowModal }) {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
                <div className="flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                    <div className="flex items-start justify-between p-5 border-b border-gray-200 rounded-t">
                        <h5 className="text-gray-600">Upload +</h5>
                        <button
                            className="p-1 ml-auto bg-transparent border-0 text-black text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => setShowModal(false)}
                        >
                            <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                            </span>
                        </button>
                    </div>
                    <div className="relative flex-auto p-6 flex justify-between">
                        <div className="w-1/2 pr-2">
                            <h1 className="font-bold">Drag & Drop Area</h1>
                            <div className="flex items-center justify-center h-32 p-6 my-3 border border-gray-400 rounded-lg">
                                <p>Drag and drop your files here</p>
                            </div>
                            <input type="file" id="fileInput" className="hidden" onChange={handleFileUpload} />
                            <label htmlFor="fileInput" className="block mt-2 text-blue-600 cursor-pointer">
                                Or click here to browse
                            </label>
                        </div>
                        <div className="w-1/2 pl-2">
                            <h1 className="font-bold">Dropdown Selector</h1>
                            <select className="w-full p-1 mt-2 border border-gray-300 rounded-md">
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex items-center justify-end p-6 border-t border-gray-200 rounded-b">
                        <button
                            className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear bg-transparent border-0 outline-none focus:outline-none"
                            type="button"
                            onClick={() => setShowModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FileManagement = () => {
    const initialFiles = [
        { id: 1, name: 'Digital Market Acts-01.pdf', type: 'pdf' },
        { id: 2, name: 'Digital Market Acts-02.doc', type: 'doc' },
        { id: 3, name: 'Digital Market Acts-03.pdf', type: 'pdf' },
        { id: 4, name: 'Digital Market Acts-04.xlsx', type: 'xlsx' },
        { id: 5, name: 'Digital Market Acts-05.pdf', type: 'pdf' },
        { id: 6, name: 'Digital Market Acts-06.pdf', type: 'pdf' },
        { id: 7, name: 'Digital Market Acts-07.xlsx', type: 'xlsx' },
        { id: 8, name: 'Digital Market Acts-08.doc', type: 'doc' },
        { id: 9, name: 'Digital Market Acts-09.doc', type: 'doc' }
    ]

    const [files, setFiles] = useState(initialFiles)
    const [searchQuery, setSearchQuery] = useState('')
    const [showModal, setShowModal] = useState(false)

    const filteredFiles = files.filter((file) => file.name.toLowerCase().includes(searchQuery.toLowerCase()))

    const handleDelete = (id) => {
        setFiles(files.filter((file) => file.id !== id))
    }

    const handleDownload = (name, type) => {
        const blob = new Blob([''], { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${name}.${type}`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0]
        if (file) {
            const newFile = { id: files.length + 1, name: file.name, type: file.name.split('.').pop() }
            setFiles([...files, newFile])
            setShowModal(false)
        }
    }
    const getFileIcon = (type) => {
        switch (type) {
            case 'pdf':
                return '/icons/pdf.png'
            case 'doc':
                return '/icons/word.png'
            case 'xlsx':
                return '/icons/xml.png'
            default:
                return '/icons/word.png'
        }
    }
    return (
        <div className="p-6 ">
            <div className="flex items-center my-3 ">
                <img className='h-5  px-1' src="/icons/folder.png" alt={`icon`} />

                <h1 className="heading ">Files</h1>
            </div>

            <div className="flex items-center p-4 justify-center mx-6">
                <div className="relative flex items-center w-full">
                    <input
                        type="text"
                        placeholder="Search by file name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full p-2  border-gray-300 rounded-lg pl-9 active:outline-none"
                    />
                    <img
                        src="/icons/search.png"
                        alt="icon"
                        className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
                    />
                </div>

                <button
                    onClick={() => setShowModal(true)}
                    className="flex items-center justify-center ml-4 px-8 py-2 bg-blue-500 text-white rounded"
                >
                    <img src="/icons/updown.png" alt="icon" className="w-5  mr-1 h-5" />
                    <p className="">Upload</p>
                </button>
            </div>
            <div className="p-4 ">
                <div className="flex flex-wrap mx-4">
                    {filteredFiles.map((file) => (
                        <div key={file.id} className="w-1/5 p-1">
                            <div className="h-[14rem] w-11/12 m-2 flex flex-col items-center p-3 bg-white border border-gray-200 rounded-lg">
                                <img
                                    src={getFileIcon(file.type)}
                                    alt={`${file.type} icon`}
                                    className="w-20 h-20 mb-2"
                                />
                                <span className="overflow-hidden text-lg pt-2 truncate w-full text-center">
                                    {file.name}
                                </span>
                                <div className="flex mt-auto">
                                    <button
                                        onClick={() => handleDelete(file.id)}
                                        className="px-2 py-1 big text-sm text-gray-700 bg-white rounded border"
                                    >
                                        Delete
                                    </button>
                                    <button
                                        onClick={() =>  handleDownload(file.name, file.type)}
                                        className="px-2 py-1 ml-2 text-sm text-blue-700 big border rounded"
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {showModal && <UploadPlus handleFileUpload={handleFileUpload} setShowModal={setShowModal} />}
        </div>
    )
}

export default FileManagement
