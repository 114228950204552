// import React, { useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { toggleFileSelection } from '../../../app/features/fileSlice'

// function Files({ children }) {
//     const selectedFilesRedux = useSelector((state) => state.files.selectedFiles)
//     const qntyFilesRedux = useSelector((state) => state.files.qntyFiles)
//     const activeButton = useSelector((state) => state.navigation.activeButton)
//     const [showModal, setShowModal] = useState(false)
//     const [data, setData] = useState([
//         'Digital Market Acts-01.pdf',
//         'Digital Market Acts-02.pdf',
//         'Digital Market Acts-03.pdf'
//     ])
//     const dispatch = useDispatch()

//     const handleClick = () => {
//         setShowModal(true)
//     }

//     const handleFileUpload = (event) => {
//         const file = event.target.files[0]
//         if (file) {
//             const fileName = file.name
//             if (!data.includes(fileName)) {
//                 setData([...data, fileName])
//             }
//             console.log('Uploaded file:', file)
//         }
//     }

//     const handleFileSelection = (index) => {
//         dispatch(toggleFileSelection(index))
//     }

//     return (
//         <>
//             <div>
//                 <div className="flex mb-2 justify-between">
//                     <div className="flex items-center">
//                         <img src="/icons/down.png" alt="logo" className="w-4 h-4 object-cover" />
//                         <p className="big">Files</p>
//                     </div>

//                     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
//                         <button
//                             htmlFor="fileInput"
//                             onClick={handleClick}
//                             className="text-blue-600 small rounded cursor-pointer"
//                         >
//                             + Add
//                         </button>
//                     </div>
//                 </div>
//                 {showModal && <UploadPlus handleFileUpload={handleFileUpload} setShowModal={setShowModal} />}
//                 <div className="flex max-h-40 h-40 overflow-y-auto flex-col py-2">
//                     {data.map((val, index) => (
//                         <button
//                             key={index}
//                             className={`my-1 mr-2 flex p-2 text-sm rounded ${
//                                 selectedFilesRedux.includes(index) ? 'bg-blue-100' : 'bg-gray-100'
//                             }`}
//                             onClick={() => {
//                                 handleFileSelection(index)
//                             }}
//                         >
//                             <button className="small no-underline hover:no-underline text-black overflow-auto">
//                                 {val}
//                             </button>
//                         </button>
//                     ))}
//                 </div>
//             </div>
//             <div className="h-20 ">
//                 {activeButton === 'KnowledgeExtracter' && qntyFilesRedux !== 1 && (
//                     <div className="bg-yellow-200 small py-2 px-4 rounded">
//                         Please select only PDF files and select or enter at least one question.
//                     </div>
//                 )}
//                 {activeButton === 'Mirror' && qntyFilesRedux !== 2 && (
//                     <div className="bg-yellow-200 small py-2 px-4 rounded">
//                         Please upload two PDF files and select or enter at least one question.
//                     </div>
//                 )}
//             </div>
//             {children}
//         </>
//     )
// }

// export default Files

// // import React, { useState } from 'react';

// function UploadPlus({ handleFileUpload, setShowModal }) {
//     const [dragging, setDragging] = useState(false)
//     const [loading, setLoading] = useState(false)
//     const [uploadedFileName, setUploadedFileName] = useState('')

//     const handleDragEnter = (e) => {
//         e.preventDefault()
//         e.stopPropagation()
//         setDragging(true)
//     }

//     const handleDragLeave = (e) => {
//         e.preventDefault()
//         e.stopPropagation()
//         setDragging(false)
//     }

//     const handleDragOver = (e) => {
//         e.preventDefault()
//         e.stopPropagation()
//     }

//     const handleDrop = (e) => {
//         e.preventDefault()
//         e.stopPropagation()
//         setDragging(false)

//         const files = e.dataTransfer.files
//         if (files && files.length > 0) {
//             setLoading(true)
//             handleFileUpload({ target: { files } })
//             e.dataTransfer.clearData()
//             setUploadedFileName(files[0].name)
//             setTimeout(() => {
//                 setLoading(false)
//             }, 2000)
//         }
//     }

//     const handleFileUploadWithLoading = (event) => {
//         setLoading(true)
//         handleFileUpload(event)
//         setUploadedFileName(event.target.files[0].name)

//         setTimeout(() => {
//             setLoading(false)
//         }, 2000) // Stop loading after 2 seconds
//     }

//     const handleProcess = () => {
//         if (!uploadedFileName) {
//             alert('Incompatible document.')
//         } else {

//             const backendResponse = 'Document added'
//             alert(backendResponse)
//         }
//     }

//     return (
//         <>
//             <div
//                 className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ${
//                     dragging ? 'bg-blue-400 bg-opacity-25' : ''
//                 }`}
//                 onDrop={handleDrop}
//                 onDragOver={handleDragOver}
//                 onDragEnter={handleDragEnter}
//                 onDragLeave={handleDragLeave}
//             >
//                 <div className="mx-auto w-[40rem]">
//                     {/* content */}
//                     <div className="border-0 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none">
//                         {/* header */}
//                         <div className="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
//                             <h5 className="text-gray-500 big mt-2">File Upload</h5>
//                             <button
//                                 className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
//                                 onClick={() => setShowModal(false)}
//                             >
//                                 <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
//                                     ×
//                                 </span>
//                             </button>
//                         </div>

//                         {/* loading */}
//                         {loading && (
//                             <div className="demo-container">
//                                 <div className="progress-bar">
//                                     <div className="progress-bar-value"></div>
//                                 </div>
//                             </div>
//                         )}

//                         <div className="flex mx-2 justify-between">
//                             <div className="flex w-6/10 border-2 my-4 border-gray-300 bg-blue-50 rounded-lg mx-3 items-center justify-center w-full">
//                                 <label
//                                     htmlFor="dropzone-file"
//                                     className="flex flex-col items-center justify-center h-64 w-full cursor-pointer"
//                                 >
//                                     <div className="flex flex-col items-center justify-center">
//                                         <svg
//                                             className="w-8 h-8 mb-4 text-gray-500"
//                                             aria-hidden="true"
//                                             xmlns="http://www.w3.org/2000/svg"
//                                             fill="none"
//                                             viewBox="0 0 20 16"
//                                         >
//                                             <path
//                                                 stroke="currentColor"
//                                                 strokeLinecap="round"
//                                                 strokeLinejoin="round"
//                                                 strokeWidth="2"
//                                                 d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
//                                             />
//                                         </svg>
//                                         <p className="small text-gray-500 ">
//                                             <span className="font-semibold">Click to upload</span> or drag and drop
//                                         </p>
//                                     </div>
//                                     <input
//                                         onChange={(e) => handleFileUploadWithLoading(e)}
//                                         id="dropzone-file"
//                                         type="file"
//                                         className="hidden"
//                                     />
//                                 </label>
//                             </div>
//                             <div className="w-4/10 mx-4 my-4 w-44">
//                                 <h1 className="big text-gray-600">Source Selection</h1>
//                                 <select className="mt-3 text-gray-500 w-full border small border-gray-300 rounded-md p-1">
//                                     {/* Populate dropdown options here */}
//                                     <option value="option1">Option 1</option>
//                                     <option value="option2">Option 2</option>
//                                     <option value="option3">Option 3</option>
//                                 </select>
//                             </div>
//                         </div>
//                         {/* footer */}
//                         <div className="flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b">
//                             <button
//                                 className="text-gray-400 py-1 font-semibold px-5 m-2 border rounded big cursor-pointer"
//                                 type="button"
//                                 onClick={() => setShowModal(false)}
//                             >
//                                 Cancel
//                             </button>
//                             <button
//                                 className="text-white bg-blue-500 py-1 font-semibold px-5 m-2  rounded big cursor-pointer"
//                                 type="button"
//                                 onClick={handleProcess}
//                             >
//                                 Process
//                             </button>
//                             <p></p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
//         </>
//     )
// }

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFileSelection, setShowMessageMirror, setShowMessageKnowledge, addFile } from '../../../app/features/fileSlice'; // Update import
import { useLocation } from 'react-router-dom';

function Files({ children }) {
    const { pathname } = useLocation();
    const showMessageMirror = useSelector((state) => state.files.showMessageMirror);
    const showMessageKnowledge = useSelector((state) => state.files.showMessageKnowledge);
    const [showFileName, setShowFileName] = useState(false);
    const selectedFilesRedux = useSelector((state) => state.files.selectedFiles);
    const qntyFilesRedux = useSelector((state) => state.files.qntyFiles);
    const activeButton = useSelector((state) => state.navigation.activeButton);
    const files = useSelector((state) => state.files.files); // Update state to use files array
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const handleClick = () => {
        setShowModal(true);
    }

    const handleFileUpload = (fileName) => {
        dispatch(addFile(fileName)); // Dispatch addFile action
        setShowModal(false); // Close modal after adding file
        console.log('Uploaded file:', fileName);
    }

    const handleFileSelection = (index) => {
        dispatch(toggleFileSelection(index));
    }

    return (
        <>
            <div>
                <div className="flex mb-2 justify-between">
                    <div className="flex items-center">
                        <img src="/icons/down.png" alt="logo" className="w-4 h-4 object-cover" />
                        <p className="big">Contents</p>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            htmlFor="fileInput"
                            onClick={handleClick}
                            className="text-blue-600 small rounded cursor-pointer"
                        >
                            + Add
                        </button>
                    </div>
                </div>
                {showModal && <UploadPlus handleFileUpload={handleFileUpload} setShowModal={setShowModal} />}
                <div className="flex max-h-40 h-40 overflow-y-auto flex-col py-2">
                    {files.map((val, index) => (
                        <button
                            key={index}
                            className={`my-1 mr-2 flex  p-2 text-sm rounded ${
                                selectedFilesRedux.includes(index) ? 'bg-blue-100' : 'bg-gray-100 hover:bg-blue-100'
                            }`}
                            onClick={() => {
                                handleFileSelection(index)
                            }}
                        >
                            <button className="small no-underline hover:no-underline text-black overflow-auto">
                                {val}
                            </button>
                        </button>
                    ))}
                </div>
            </div>
            <div className="h-20 ">
                {showMessageKnowledge && pathname === '/playground/KnowledgeExtractor' && (
                    <div className="bg-yellow-200 small py-2 px-4 rounded">
                        Please select only PDF files and select or enter at least one question.
                    </div>
                )}
                {/* {showMessageKnowledge && activeButton === 'KnowledgeExtracter' && qntyFilesRedux !== 1 && (
                    <div className="bg-yellow-200 small py-2 px-4 rounded">
                        Please select only PDF files and select or enter at least one question.
                    </div>
                )} */}
                {showMessageMirror && pathname === '/playground/Mirror' && (
                    <div className="bg-yellow-200 small py-2 px-4 rounded">
                        Please upload two PDF files and select or enter at least one question.
                    </div>
                )}

                {/* {showMessageMirror && activeButton === 'Mirror' && qntyFilesRedux !== 2 && (
                    <div className="bg-yellow-200 small py-2 px-4 rounded">
                        Please upload two PDF files and select or enter at least one question.
                    </div>
                )} */}
            </div>
            {children}
        </>
    )
}

export default Files;


function UploadPlus({ handleFileUpload, setShowModal }) {
    const [dragging, setDragging] = useState(false)
    const [loading, setLoading] = useState(false)
    const [uploadedFileName, setUploadedFileName] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [addedMessage, setAddedMessage] = useState('')
    const [fileName, setFileName] = useState('')
    const [loadingSpiral, setLoadingSpiral] = useState(true)
    const handleDragEnter = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(true)
    }

    const handleDragLeave = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
    }

    const handleDragOver = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
        setFileName('')
        setErrorMessage('')
        const files = e.dataTransfer.files
        if (files && files.length > 0) {
            setUploadedFileName(files[0].name)
            
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                if (!files[0].name) {
                    setErrorMessage('Incompatible document!')
                } else {
                    setFileName(files[0].name)

                   
                }
            }, 3000)
        }
    }

    // const handleDrop = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setDragging(false);

    //     const files = e.dataTransfer.files;
    //     if (files && files.length > 0) {
    //         setUploadedFileName(files[0].name);
    //     }
    // };

    const handleFileUploadWithLoading = (event) => {
        setErrorMessage('')
        setFileName('')
        setLoading(true)
        setTimeout(()=>{ 
            setLoading(false)
            setUploadedFileName(event.target.files[0].name)
            setFileName(event.target.files[0].name)
        },3000)
    }
    const handleProcess = () => {
        setErrorMessage('')
        setFileName('')
        if (!uploadedFileName) {
        // logic for the incompitable file
        // if (!uploadedFileName) {
        //     setLoading(true)
        //     setFileName('')
        //     setLoadingSpiral(false)
        //     setTimeout(() => {
        //         setLoading(false)
        //         setLoadingSpiral(true)
        //         setErrorMessage('Incompatible document!')
        //     }, 3000)
        } else {
          
            setLoadingSpiral(false)
            setLoading(true)
            setLoading(true)
            setFileName('')
            setTimeout(() => {
                setLoadingSpiral(true)
                setFileName('')
                setLoading(false)
                handleFileUpload(uploadedFileName)
                setUploadedFileName('') // Reset uploadedFileName after processing
                setErrorMessage('Document added!')
                setAddedMessage(' ')
            }, 3000)
        }
    }

    // Reset errorMessage when handleProcess is called without uploading a file

    return (
        <>
            <div
                className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ${
                    dragging ? 'bg-blue-400 bg-opacity-25' : ''
                }`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
            >
                <div className="mx-auto w-[40rem]">
                    {/* content */}
                    <div className="border-0 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/* header */}
                        <div className="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
                            <h5 className="text-gray-500 big mt-2">Content Upload</h5>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>

                        {/* loading */}
                        <div className="h-[10px]">
                            {loading && (
                                <div className="demo-container">
                                    <div className="progress-bar">
                                        <div className="progress-bar-value"></div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {loadingSpiral ? (
                            <>
                                <div className="h-4 ml-3 mb-1">
                                    {errorMessage !== '' && (
                                        <div
                                            className={` small p-2 ${
                                                errorMessage === 'Incompatible document!'
                                                    ? 'text-red-500'
                                                    : 'text-green-500'
                                            } `}
                                        >
                                            {errorMessage}
                                        </div>
                                    )}
                                </div>
                                <div className="flex mx-2 justify-between">
                                    <div className="flex w-6/10 border-2 my-4 border-gray-300 bg-blue-50 rounded-lg mx-3 items-center justify-center w-full">
                                        <label
                                            htmlFor="dropzone-file"
                                            className="flex flex-col items-center justify-center h-64 w-full cursor-pointer"
                                        >
                                            <div className="flex flex-col items-center justify-center">
                                                <svg
                                                    className="w-8 h-8 mb-4 text-gray-500"
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 20 16"
                                                >
                                                    <path
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                    />
                                                </svg>
                                                <div className="small text-gray-500 ">
                                                    {fileName !== '' ? (
                                                       <p>{fileName}</p>
                                                    ) : (
                                                        <p>
                                                            
                                                        <span className="font-semibold">Click to upload</span> or
                                                        drag and drop{' '}
                                                    </p>
                                                        
                                                    )}
                                                </div>
                                            </div>
                                            <input
                                                onChange={(e) => handleFileUploadWithLoading(e)}
                                                id="dropzone-file"
                                                type="file"
                                                className="hidden"
                                            />
                                        </label>
                                    </div>
                                    <div className="w-4/10 mx-4 my-4 w-44">
                                        <h1 className="big text-gray-600">Source Selection</h1>
                                        <select className="mt-3 text-gray-500 w-full border small border-gray-300 rounded-md p-1">
                                            {/* Populate dropdown options here */}
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-gray-400 py-1 font-semibold px-5 m-2 border rounded big cursor-pointer"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="text-white bg-blue-500 py-1 font-semibold px-5 m-2  rounded big cursor-pointer"
                                        type="button"
                                        onClick={handleProcess}
                                    >
                                        Process
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="  h-[365px]  flex flex-col items-center justify-center  ">
                                
                                <h1 className="font-bold text-lg"> Processing Your File </h1>
                                                          </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}
