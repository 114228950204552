import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveButton } from './../../../app/features/navigationSlice'
import TutorialModal from './TutorialModel'
import CleanData from './cleandata'

const SubHeader = () => {
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const activeButton = useSelector((state) => state.navigation.activeButton)

    const handleButtonClick = (buttonName) => {
        dispatch(setActiveButton(buttonName))
    }

    return (
        <div className="flex border-b py-2 justify-between">
            {/* Buttons aligned to the left */}
            <div className="flex mx-2 justify-center items-center">
                <Link
                    className={`no-underline flex  hover:no-underline text-black m-1 mt-1 hover:bg-blue-100 px-3 py-2  rounded ${
                        pathname === '/playground' ? 'bg-blue-100 rounded' : `${pathname === '/playground/SmartChat' ? 'bg-blue-100 rounded' : 'bg-gray-100'}`
                    } `}
                    onClick={() => {
                        handleButtonClick('SmartChat')
                        // console.log(activeButton)
                    }}
                    to="/playground/SmartChat"
                >
                    <p className="px-1 big">Smart</p>
                    <p  className='big'>Chat</p>
                </Link>
                <Link
                    className={`no-underline flex hover:no-underline   text-black  m-1 mt-1 hover:bg-blue-100 px-3 py-2  rounded ${
                        pathname === '/playground/KnowledgeExtractor' ? ' bg-blue-100 rounded' : 'bg-gray-100'
                    }`}
                    onClick={() => {
                        handleButtonClick('KnowledgeExtracter')
                        // console.log(activeButton)
                    }}
                    to="/playground/KnowledgeExtractor"
                >
                    <p className="px-1 big">Knowledge</p>
                    <p  className='big'>Extractor</p>
                </Link>

                <Link
                    className={`no-underline hover:no-underline active:bg-blue-100  text-black  m-1 mt-1 hover:bg-blue-100  px-3 py-2 big rounded ${
                        pathname === '/playground/Mirror' ? ' bg-blue-100 rounded' : 'bg-gray-100'
                    }`}
                    onClick={() => {
                        handleButtonClick('Mirror')
                        // console.log(activeButton)
                    }}
                    to="/playground/Mirror"
                >
                    Mirror
                </Link>
            </div>

            {/* Buttons aligned to the right */}
            <div className="flex ">
                <CleanData />
                <TutorialModal />
            </div>
        </div>
    )
}

export default SubHeader
