import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  value: '',
  status: false,
  selectedFiles: [], // Array to store selected file indices
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    toggleStatus: (state) => {
      state.status = !state.status;
    },
    editText: (state, action) => {
      state.value = action.payload;
    },
    toggleFileSelection: (state, action) => {
      const index = action.payload;
      const selectedIndex = state.selectedFiles.indexOf(index);
      if (selectedIndex === -1) {
        // If file index not found, add it to selectedFiles
        state.selectedFiles.push(index);
      } else {
        // If file index found, remove it from selectedFiles
        state.selectedFiles.splice(selectedIndex, 1);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleStatus, editText, toggleFileSelection } = counterSlice.actions;

export default counterSlice.reducer;

