import { Divider, tableCellClasses } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import SortableTh from '../Bookmark/SortableTh'

export default function Bookmark() {
    const [details, setDetails] = useState([
        {
            SN0: 0,
            From: 'Knowledge Extractor',
            Time: 'Monday 10 April 2024, 09:10:03'
        },
        {
            SN0: 1,
            From: 'Smart Chat',
            Time: 'Tuesday 01 April 2025, 09:10:03'
        },
        {
            SN0: 2,
            From: 'Mirror',
            Time: 'Wednesday 01 April 2023, 09:10:03'
        },
        {
            SN0: 3,
            From: 'Knowledge Extractor',
            Time: 'Thursday 01 April 2024, 09:10:03'
        },
        {
            SN0: 4,
            From: 'Knowledge Extractor',
            Time: 'Friday 01 April 2024, 09:10:03'
        }
    ])

    const [originalOpen, setOriginalOpen] = useState(false)
    const [aiOpen, setAiOpen] = useState(false)

    const [sidebarVisible, setSidebarVisible] = useState(false)
    const [selectedDetails, setSelectedDetails] = useState(null)
    const [copyOn, setCopyOn] = useState(false)

    const openSidebar = (index) => {
        setSelectedDetails(details[index])
        setSidebarVisible(true)
    }

    const closeSidebar = () => {
        setSidebarVisible(false)
        setSelectedDetails(null)
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopyOn(true);
                setTimeout(() => {
                    setCopyOn(false);
                }, 2000);
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };
    

    const textForCopy =
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus magni omnis sit est! Eos expedita omnis sapiente necessitatibus. Corrupti voluptatibus, vero, odit ratione dolorum porro vel ut possimus et obcaecati alias libero asperiores magni accusamus excepturi quaerat magnam harum ducimus consectetur? Officiis soluta magnam, hic velit repudiandae dignissimos vero minima, rem accusamus voluptatibus dolores magni fuga itaque perferendis, a unde impedit deserunt illum? Sed.'

    const [sortConfig, setSortConfig] = useState({ key: null, direction: '' })

    const dayToIndex = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7
    }

    const sortedDetails = React.useMemo(() => {
        let sortableDetails = [...details]
        if (sortConfig.key) {
            sortableDetails.sort((a, b) => {
                if (sortConfig.key === 'Time') {
                    const timeA = a[sortConfig.key]
                    const timeB = b[sortConfig.key]

                    const isFullDateA = /\d{2} \w+ \d{4}/.test(timeA)
                    const isFullDateB = /\d{2} \w+ \d{4}/.test(timeB)

                    if (isFullDateA && isFullDateB) {
                        const dateA = new Date(
                            timeA.replace(/(\d{2}) (\w+) (\d{4}), (\d{2}:\d{2}:\d{2})/, '$2 $1, $3 $4')
                        )
                        const dateB = new Date(
                            timeB.replace(/(\d{2}) (\w+) (\d{4}), (\d{2}:\d{2}:\d{2})/, '$2 $1, $3 $4')
                        )

                        if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1
                        if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1
                        return 0
                    } else if (!isFullDateA && !isFullDateB) {
                        const dayNameA = timeA.split(' ')[0]
                        const dayNameB = timeB.split(' ')[0]

                        const dayIndexA = dayToIndex[dayNameA]
                        const dayIndexB = dayToIndex[dayNameB]

                        if (dayIndexA < dayIndexB) return sortConfig.direction === 'ascending' ? -1 : 1
                        if (dayIndexA > dayIndexB) return sortConfig.direction === 'ascending' ? 1 : -1
                        return 0
                    } else {
                        return isFullDateA ? -1 : 1
                    }
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1
                    }
                    return 0
                }
            })
        }
        return sortableDetails
    }, [details, sortConfig])

    return (
        <div className="my-4 ">
            <div className="text-2xl flex items-center mx-2 gap-2 my-5 ">
                <img className='h-6 w-6' src={`${process.env.PUBLIC_URL}/icons/logdark.png`} />
                <h1 className="heading ">Logs</h1>
            </div>

            <div className="w-full ">
                <table className="w-full md:w-full lg:w-full border rounded-xl overflow-hidden">
                    <thead className="text-xs text-gray-400  bg-gray-200 text-left">
                        <tr>
                            <th scope="col" className="big">
                                S.NO
                            </th>

                            <SortableTh
                                title="Date/Time"
                                sortKey="Time"
                                // onSort={onSort}
                                sortConfig={sortConfig}
                                setSortConfig={setSortConfig}
                            />
                            <SortableTh
                                title="From"
                                sortKey="From"
                                // onSort={onSort}
                                sortConfig={sortConfig}
                                setSortConfig={setSortConfig}
                            />
                            <th scope="col" className="big text-left pl-9">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedDetails.map((item, index) => (
                            <tr key={index} className={`bg-white text-black  border-b  `}>
                                <th
                                    scope="row"
                                    className="gap-1 small text-gray-900 whitespace-nowrap "
                                >
                                    {index < 9 ? (
                                        <>
                                            <p>0{index + 1}</p>
                                        </>
                                    ) : (
                                        <>
                                            <p>{index + 1}</p>
                                        </>
                                    )}
                                </th>
                                {/* <th className="text-left gap-1">{item.Name}</th> */}
                                <td className="gap-1 small">{item.Time}</td>
                                <th className="gap-1 small">{item.From}</th>
                                <td className="   ">
                                    <div className="flex gap-2">
                                        <button
                                            onClick={() => openSidebar(index)}
                                            className="border small pt-1 pr-4 pb-1 small pl-4 rounded text-blue-600"
                                        >
                                            See Details
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {sidebarVisible && (
                <div className="fixed top-0 right-0 w-1/4 h-full bg-white shadow-lg z-50">
                    <div className="ml-3 mr-3 ">
                        <div>
                            {selectedDetails && (
                                <div className="">
                                    <div className="flex justify-between mt-4">
                                        <h1 className="small mb-2 font-semibold">{selectedDetails.Name}</h1>
                                        <button
                                            onClick={closeSidebar}
                                            className="mb-4 small text-gray-500  flex items-center "
                                        >
                                            <img src={`${process.env.PUBLIC_URL}/icons/x.png`} className="h-3 mr-1" />
                                            Close
                                        </button>
                                    </div>
                                    <div className="small">
                                        <p className="mb-2 small ">
                                            From: <span className="font-semibold ">{selectedDetails.From}</span>
                                        </p>

                                        <p className="mb-5 small">
                                            Date/Time: <span className="font-semibold">{selectedDetails.Time}</span>
                                        </p>
                                        <Divider />
                                    </div>
                                    <p className="mt-4 big font-semibold">Source</p>
                                    <div className="mt-3 small w-full py-3 px-4 rounded border border-gray-300">
                                        Digital Market Acts-01.pdf...
                                    </div>
                                    <div className=" flex justify-between items-center mt-3">
                                        <strong className="mt-3 big">Output</strong>
                                        {copyOn && (
                                                <div
                                                    class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 "
                                                    role="alert"
                                                >
                                                    <span class="font-medium">Text Copied</span>
                                                </div>
                                            )}
                                    </div>

                                    <div className="w-full h-full border mt-3 border-gray rounded-xl ">
                                        <div className="flex justify-start  items-center gap-2 m-3">
                                            <div className=" ">
                                                <img className='w-7' src={`${process.env.PUBLIC_URL}/icons/user.png`} />
                                            </div>

                                            <h2 className="small mx-3 text-gray font-semibold flex justify-start w-full">
                                                Original Text
                                            </h2>

                                            <button onClick={() => copyToClipboard(textForCopy)}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/icons/copy.png`}
                                                    className="h-5 w-7"
                                                />
                                            </button>
                                           
                                            <button
                                                className="h-3 mb-3 flex "
                                                type="button"
                                                onClick={() => setOriginalOpen(!originalOpen)}
                                            >
                                                {originalOpen ? (
                                                    <img className='h-6' src={`${process.env.PUBLIC_URL}/icons/up.png`} />
                                                ) : (
                                                    <img className='h-6' src={`${process.env.PUBLIC_URL}/icons/down.png`} />
                                                )}
                                            </button>
                                        </div>
                                        {originalOpen && <p className="m-3 mt-5 small">{textForCopy}</p>}
                                    </div>

                                    <div className="w-full h-full border mt-3 border-gray rounded-xl bg-purple-100">
                                        <div className="flex items-center gap-2 m-3">
                                            <div className=" rounded-full ">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/icons/ai.png`}
                                                    className="w-7 "
                                                />
                                            </div>

                                            <h2 className="small mx-3 text-gray font-semibold w-1/2">AI Generated</h2>
                                            <div className="flex justify-end w-full gap-2">
                                                <button onClick={() => copyToClipboard(textForCopy)}>
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/icons/copy.png`}
                                                        className="h-5 w-5"
                                                    />
                                                </button>
                                                <button
                                                    className="h-1 mb-3 "
                                                    type="button"
                                                    onClick={() => setAiOpen(!aiOpen)}
                                                >
                                                    {aiOpen ? (
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/icons/up.png`}
                                                            className="h-6"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/icons/down.png`}
                                                            className="h-6"
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        {aiOpen && <p className="m-3 mt-5 small">{textForCopy}</p>}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
