import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setShowMessageKnowledge } from '../../../app/features/fileSlice'
import { setPdfStatus, setPrompt, setLoadingShow } from '../../../app/features/knowledgeSlice'


export default function KnowledgeExtractorChat() {
    

    // global session logic
    const dispatch = useDispatch();
    const { pdfStatus, prompt, loadingShow } = useSelector((state) => state.knowledge);
  
    
  
    const handleClick = (buttonId) => {
      if (buttonId === 'generatePdf') {
        dispatch(setPdfStatus('generating'));
       
        setTimeout(() => {
          dispatch(setPdfStatus('downloaded'));
        }, 3000); // 
      }
    };
  
    const handleGenerateSubmit = () => {
      if (qntyFilesRedux === 1) {
        setSubmitted(true);
        dispatch(setPrompt(text));
        setText('');
        dispatch(setPdfStatus(''));
        handleShowMessageKnowledge(false);
        dispatch(setLoadingShow(false));
   
        setTimeout(() => {
          dispatch(setLoadingShow(true));
          
        }, 3000);
        
      } else {
        handleShowMessageKnowledge(true);
      }
    };

   
// local logic


const handleShowMessageKnowledge = (x) => {
    dispatch(setShowMessageKnowledge(x))
}
    const [text, setText] = useState('How can I help you?')
    const [isOpen, setIsOpen] = useState(false)
    const qntyFilesRedux = useSelector((state) => state.files.qntyFiles)
    const activeButton = useSelector((state) => state.navigation.activeButton)
    
    const [chatshow, setChatshow] = useState(false)
   
    const [isStopped, setIsStopped] = useState(true)

    
    const [submitted, setSubmitted] = useState(true)
    
    const textareaRef = useRef(null)

    useEffect(() => {
        adjustTextareaHeight()
    }, [text])

    const { presets } = useSelector((state) => state.knowledgetask);
    const [syncWithRedux, setSyncWithRedux] = useState(true);
    useEffect(() => {
        if (syncWithRedux) {
            const selectedPreset = presets.find((preset) => preset.selected);
            if (selectedPreset) {
                setText(selectedPreset.textField);
            } else {
                setText('How can I help you?');
            }
        }
    }, [presets, syncWithRedux]);

    const handleTextareaChange = (event) => {
        setText(event.target.value)
    }

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current
        if (textarea) {
            textarea.style.height = 'auto'
            textarea.style.height = Math.min(textarea.scrollHeight, 0.1 * window.innerHeight) + 'px'
        }
    }

    const toggleModal = () => {
        setIsOpen(!isOpen)
    }
    const [showOriginalText, setShowOriginalText] = useState(true)
    const [showGeneratedText, setShowGeneratedText] = useState(true)

    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                // alert('Text copied to clipboard')
            })
            .catch((err) => {
                console.error('Failed to copy: ', err)
            })
    }

    return (
        <>
            <div style={{ height: '54vh' }} className="items-center justify-center">
                <div className="h-[50px]  pt-4 px-4 flex flex-row-reverse">
                    <button onClick={toggleModal} className="p-2 border rounded big text-gray-700">
                        Sections
                    </button>
                </div>
                {/* here  */}

                <div className="flex h-full justify-center">
                    {loadingShow === null ? (
                        <div className="flex items-center h-full  justify-center">
                            <div className="flex flex-col items-center justify-center">
                                {pdfStatus === 'generating' ? (
                                    <>
                                        <div className="flex flex-col items-center justify-center">
                                            <div>
                                                <div
                                                    className="inline-block text-black h-10 w-10 mx-1 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                                    role="status"
                                                >
                                                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                                        Loading...
                                                    </span>
                                                </div>
                                            </div>
                                            <h1 className="font-bold text-lg"> Generating PDF </h1>
                                            <h4 className="text-gray-400">
                                                Please be patient as we are generating the
                                            </h4>
                                            <h4 className="text-gray-400">PDF. This may take some time</h4>
                                        </div>
                                    </>
                                ) : pdfStatus === 'downloaded' ? (
                                    <>
                                        <div className="flex flex-col items-center justify-center">
                                            <div className="h-10 w-10 mb-1 "></div>
                                            <h1 className="font-bold text-lg"> PDF Generated Successfully</h1>
                                            <h4 className="text-gray-400">You can download the pdf from the</h4>
                                            <h4 className="text-gray-400">'Download PDF' button below</h4>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    ) : (
                        <>
                            {loadingShow ? (
                                <div className="flex h-full ">
                                    {submitted && (
                                        <div className="flex mt-10 w-full justify-center ">
                                            <div className="max-h-90 h-90 w-6/12 overflow-y-auto ">
                                                <div
                                                    className="p-2 mx-3 border rounded-lg bg-white"
                                                    style={{ width: '350px' }}
                                                >
                                                    <div className="flex justify-between my-2">
                                                        <div className="flex items-center gap-1">
                                                            <button className="bg-gray-100 border py-1 px-1 m-1 rounded-lg">
                                                                <img className="w-6" src="/icons/user.png" alt="logo" />
                                                            </button>
                                                            <p className="font-bold big">Original Text</p>
                                                        </div>
                                                        <div className="flex items-center gap-1">
                                                            <button
                                                                className="m-1"
                                                                onClick={() => copyToClipboard(prompt)}
                                                            >
                                                                <img
                                                                    className="h-5 w-5"
                                                                    src="/icons/copy.png"
                                                                    alt="Copy"
                                                                />
                                                            </button>
                                                            <button
                                                                className="m-1"
                                                                onClick={() => setShowOriginalText(!showOriginalText)}
                                                            >
                                                                {showOriginalText ? (
                                                                    <img
                                                                        className="h-5 w-5"
                                                                        src="/icons/up.png"
                                                                        alt="Hide"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className="h-5 w-5"
                                                                        src="/icons/down.png"
                                                                        alt="Show"
                                                                    />
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {showOriginalText && <p className="small">{prompt}</p>}
                                                </div>
                                            </div>
                                            <div className="max-h-90 h-90 overflow-y-auto w-6/12  ">
                                                <div
                                                    className="p-2 mx-3 border  rounded-lg bg-blue-50"
                                                    style={{ width: '350px' }}
                                                >
                                                    <div className="flex justify-between my-2">
                                                        <div className="flex items-center gap-1">
                                                            <button className="bg-gray-100 border py-1 px-1 m-1 rounded-lg">
                                                                <img className="w-6" src="/icons/ai.png" alt="logo" />
                                                            </button>
                                                            <p className="font-bold big">AI Generated</p>
                                                        </div>
                                                        <div className="flex items-center gap-1">
                                                            <button
                                                                className="m-1"
                                                                onClick={() =>
                                                                    copyToClipboard('Your generated text here')
                                                                }
                                                            >
                                                                <img
                                                                    className="h-5 w-5"
                                                                    src="/icons/copy.png"
                                                                    alt="Copy"
                                                                />
                                                            </button>
                                                            <button
                                                                className="m-1"
                                                                onClick={() => setShowGeneratedText(!showGeneratedText)}
                                                            >
                                                                {showGeneratedText ? (
                                                                    <img
                                                                        className="h-5 w-5"
                                                                        src="/icons/up.png"
                                                                        alt="Hide"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className="h-5 w-5"
                                                                        src="/icons/down.png"
                                                                        alt="Show"
                                                                    />
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {showGeneratedText && (
                                                        <p className="small">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Asperiores ex delectus commodi nesciunt, adipisci minima
                                                            ipsa, quia quam ducimus sed autem aliquam, rerum distinctio
                                                            qui molestiae eos doloribus inventore. Facilis?
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-center">
                                    <div>
                                        <div
                                            className="inline-block text-black h-10 w-10 mx-1 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                            role="status"
                                        >
                                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                                Loading...
                                            </span>
                                        </div>
                                    </div>
                                    <h1 className="font-bold text-lg"> Processing Data </h1>
                                    <h4 className="text-gray-400">Please be patient as we are generating the Data.</h4>
                                    <h4 className="text-gray-400">This may take some time</h4>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="my-2">{isOpen && <RightDrawer isOpen={isOpen} onClose={toggleModal} />}</div>

            <div className="mx-[4rem] mt-4 " style={{ height: '20vh' }}>
                <div className="mb-6 mt-4">
                    <div className="flex justify-center mt-6 m-1 items-center">
                        <div className="mx-auto flex justify-between  items-center ">
                            {pdfStatus === 'generating' ? (
                                <>
                                    <button
                                        className="flex text-blue-700 bg-gray-100 justify-center items-center border py-2 px-4 rounded mr-2 big  "
                                        onClick={() => handleClick('generatePdf')}
                                        disabled={pdfStatus === 'generating'}
                                    >
                                        {/* spinner */}
                                        <div>
                                            <div
                                                className="inline-block h-4 w-4 mx-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                                role="status"
                                            >
                                                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                                    Loading...
                                                </span>
                                            </div>
                                        </div>
                                        <p className="big">Generating PDF</p>
                                    </button>
                                </>
                            ) : pdfStatus === 'downloaded'  ? (
                                <>
                                    <button
                                        className="flex bg-blue-700 text-white justify-center items-center border py-2 px-4 rounded mr-2 big  "
                                        onClick={() => {
                                            handleClick('generatePdf')
                                            dispatch(setLoadingShow(null));
  
                                        }}
                                        disabled={pdfStatus === 'generating'}
                                    >
                                        <img className="h-5 w-5 mx-2" src="/icons/updown.png" alt="logo" />
                                        <p className="big">Download PDF</p>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        className="flex  justify-center items-center border py-2 px-4 rounded mr-2 big "
                                        onClick={() => {
                                            handleClick('generatePdf')
                                            dispatch(setLoadingShow(null));
  
                                        }}
                                        disabled={pdfStatus === 'generating'}
                                    >
                                        <img className="h-5 w-5 mx-2" src="/icons/download.png" alt="logo" />
                                        <p className="big">Generate PDF</p>
                                    </button>
                                </>
                            )}

                            <button class="flex justify-center items-center  border py-2 px-4 rounded mr-2">
                                <img className="h-5 w-5 mx-2" src="/icons/download.png" alt="logo" />
                                <p className="big">Generate Excel</p>
                            </button>
                            <button class="flex justify-center items-center  border py-2 px-4 rounded mr-2">
                                <img className="h-5 w-5 mx-2" src="/icons/download.png" alt="logo" />
                                <p className="big">Bookmark</p>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center  items-center w-full">
                    <div className="mx-22 justify-center items-center w-4/5  flex  border-2 rounded-lg shadow-md active:border">
                        <textarea
                            ref={textareaRef}
                            style={{ maxHeight: '60px', height: '0px' }}
                            className="w-full small py-2 overflow-y-auto items-center px-5 focus:outline-none active:no-border"
                            rows={2}
                            value={text}
                            onChange={(e) => handleTextareaChange(e)}
                            placeholder="How can I help you?"
                        ></textarea>

                        <div className="flex items-center justify-between">
                            <button
                                style={{
                                    backgroundImage:
                                        'linear-gradient(to right, rgba(123, 97, 255, 1), rgba(82, 60, 196, 1))'
                                }}
                                className=" py-2 px-1 rounded-md"
                                onClick={() => {
                                    handleGenerateSubmit()
                                }}
                            >
                                <div className="mx-5 my-[2px] text-white flex align-center justify-center ">
                                    <img className="h-4 w-4 mr-1" src="/icons/whiteStar.png" alt="Generate" />
                                    <p className="ml-1 big">Generate</p>
                                </div>
                            </button>

                            {/* {activeButton === 'KnowledgeExtracter' && qntyFilesRedux === 1 ? (
                                <button
                                    style={{
                                        backgroundImage:
                                            'linear-gradient(to right, rgba(123, 97, 255, 1), rgba(82, 60, 196, 1))'
                                    }}
                                    className=" py-2 px-1 rounded-md"
                                    onClick={() => {
                                        handleSubmit()
                                        handleClickBetweenLoadingAndChat()
                                        setPrompt(text)
                                        setText('')
                                        setSyncWithRedux(!syncWithRedux)
                                        setPdfStatus('')
                                    }}
                                >
                                    <div className="mx-5 my-[2px] text-white flex align-center justify-center ">
                                        <img className="h-4 w-4 mr-1" src="/icons/whiteStar.png" alt="Generate" />
                                        <p className="ml-1 big">Generate</p>
                                    </div>
                                </button>
                            ) : (
                                <button className=" bg-gray-50 py-2 px-1 rounded-md">
                                    <div className="mx-5 my-[2px] text-gray-700  flex align-center justify-center ">
                                        <img className="h-4 w-4 mr-1" src="/icons/grayStar.png" alt="Generate" />
                                        <p className="ml-1 big  text-gray-400">Generate</p>
                                    </div>
                                </button>
                            )} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// import React, { useState, useEffect, useRef } from 'react';
// import { useSelector } from 'react-redux';
// import Cookies from 'js-cookie';

// export default function KnowledgeExtractorChat() {
//     const [pdfStatus, setPdfStatus] = useState(Cookies.get('pdfStatus') || '');
//     const [loadingShow, setLoadingShow] = useState(Cookies.get('loadingShow') === 'true');
//     const [isStopped, setIsStopped] = useState(Cookies.get('isStopped') === 'true');
//     const [isOpen, setIsOpen] = useState(Cookies.get('isOpen') === 'true');
//     const [text, setText] = useState(Cookies.get('text') || 'How can I help you?');
//     const [submitted, setSubmitted] = useState(Cookies.get('submitted') === 'true');
//     const [prompt, setPrompt] = useState(Cookies.get('prompt') || '');
//     const [syncWithRedux, setSyncWithRedux] = useState(true);
//     const [showOriginalText, setShowOriginalText] = useState(Cookies.get('showOriginalText') === 'true');
//     const [showGeneratedText, setShowGeneratedText] = useState(Cookies.get('showGeneratedText') === 'true');

//     const textareaRef = useRef(null);
//     const qntyFilesRedux = useSelector((state) => state.files.qntyFiles);
//     const activeButton = useSelector((state) => state.navigation.activeButton);
//     const value = useSelector((state) => state.counter.value);

//     const saveStateToCookies = () => {
//         Cookies.set('pdfStatus', pdfStatus);
//         Cookies.set('loadingShow', loadingShow);
//         Cookies.set('isStopped', isStopped);
//         Cookies.set('isOpen', isOpen);
//         Cookies.set('text', text);
//         Cookies.set('submitted', submitted);
//         Cookies.set('prompt', prompt);
//         Cookies.set('showOriginalText', showOriginalText);
//         Cookies.set('showGeneratedText', showGeneratedText);
//     };

//     useEffect(() => {
//         window.addEventListener('beforeunload', saveStateToCookies);
//         return () => {
//             window.removeEventListener('beforeunload', saveStateToCookies);
//         };
//     }, [pdfStatus, loadingShow, isStopped, isOpen, text, submitted, prompt, showOriginalText, showGeneratedText]);

//     useEffect(() => {
//         adjustTextareaHeight();
//     }, [text]);

//     useEffect(() => {
//         if (syncWithRedux) {
//             setText(value);
//         }
//     }, [value, syncWithRedux]);

//     const handleTextareaChange = (event) => {
//         setText(event.target.value);
//     };

//     const adjustTextareaHeight = () => {
//         const textarea = textareaRef.current;
//         if (textarea) {
//             textarea.style.height = 'auto';
//             textarea.style.height = Math.min(textarea.scrollHeight, 0.1 * window.innerHeight) + 'px';
//         }
//     };

//     const toggleModal = () => {
//         setIsOpen(!isOpen);
//     };

//     const copyToClipboard = (text) => {
//         navigator.clipboard.writeText(text).then(() => {
//             // alert('Text copied to clipboard')
//         }).catch((err) => {
//             console.error('Failed to copy: ', err);
//         });
//     };

//     const handleClickBetweenLoadingAndChat = () => {
//         setLoadingShow(false);
//         setTimeout(() => {
//             setLoadingShow(true);
//         }, 3000);
//     };

//     const handleClick = (buttonId) => {
//         if (buttonId === 'generatePdf') {
//             setPdfStatus('generating');
//             setTimeout(() => {
//                 setPdfStatus('downloaded');
//             }, 3000);
//         }
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         setSubmitted(true);
//         setPrompt(text);
//     };

//     return (
//         <>
//             <div style={{ height: '54vh' }} className="items-center justify-center">
//                 <div className="h-[50px] pt-4 px-4 flex flex-row-reverse">
//                     <button onClick={toggleModal} className="p-2 border rounded big text-gray-700">
//                         Sections
//                     </button>
//                 </div>
//                 <div className="flex h-full justify-center">
//                     {loadingShow === null ? (
//                         <div className="flex items-center h-full justify-center">
//                             <div className="flex flex-col items-center justify-center">
//                                 {pdfStatus === 'generating' ? (
//                                     <>
//                                         <div className="flex flex-col items-center justify-center">
//                                             <div>
//                                                 <div
//                                                     className="inline-block text-black h-10 w-10 mx-1 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
//                                                     role="status"
//                                                 >
//                                                     <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
//                                                         Loading...
//                                                     </span>
//                                                 </div>
//                                             </div>
//                                             <h1 className="font-bold text-lg"> Generating PDF </h1>
//                                             <h4 className="text-gray-400">Please be patient as we are generating the PDF. This may take some time</h4>
//                                         </div>
//                                     </>
//                                 ) : pdfStatus === 'downloaded' ? (
//                                     <>
//                                         <div className="flex flex-col items-center justify-center">
//                                             <div className="h-10 w-10 mb-1 "></div>
//                                             <h1 className="font-bold text-lg"> PDF Generated Successfully</h1>
//                                             <h4 className="text-gray-400">You can download the pdf from the 'Download PDF' button below</h4>
//                                         </div>
//                                     </>
//                                 ) : (
//                                     <></>
//                                 )}
//                             </div>
//                         </div>
//                     ) : (
//                         <>
//                             {loadingShow ? (
//                                 <div className="flex h-full ">
//                                     {submitted && (
//                                         <div className="flex mt-10 w-full justify-center ">
//                                             <div className="max-h-90 h-90 w-6/12 overflow-y-auto ">
//                                                 <div className="p-2 mx-3 border rounded-lg bg-white" style={{ width: '350px' }}>
//                                                     <div className="flex justify-between my-2">
//                                                         <div className="flex items-center gap-1">
//                                                             <button className="bg-gray-100 border py-1 px-1 m-1 rounded-lg">
//                                                                 <img className="w-6" src="/icons/user.png" alt="logo" />
//                                                             </button>
//                                                             <p className="font-bold big">Original Text</p>
//                                                         </div>
//                                                         <div className="flex items-center gap-1">
//                                                             <button className="m-1" onClick={() => copyToClipboard(prompt)}>
//                                                                 <img className="h-5 w-5" src="/icons/copy.png" alt="Copy" />
//                                                             </button>
//                                                             <button className="m-1" onClick={() => setShowOriginalText(!showOriginalText)}>
//                                                                 {showOriginalText ? (
//                                                                     <img className="h-5 w-5" src="/icons/up.png" alt="Hide" />
//                                                                 ) : (
//                                                                     <img className="h-5 w-5" src="/icons/down.png" alt="Show" />
//                                                                 )}
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                     {showOriginalText && <p className="small">{prompt}</p>}
//                                                 </div>
//                                             </div>
//                                             <div className="max-h-90 h-90 overflow-y-auto w-6/12">
//                                                 <div className="p-2 mx-3 border rounded-lg bg-blue-50" style={{ width: '350px' }}>
//                                                     <div className="flex justify-between my-2">
//                                                         <div className="flex items-center gap-1">
//                                                             <button className="bg-gray-100 border py-1 px-1 m-1 rounded-lg">
//                                                                 <img className="w-6" src="/icons/ai.png" alt="logo" />
//                                                             </button>
//                                                             <p className="font-bold big">AI Generated</p>
//                                                         </div>
//                                                         <div className="flex items-center gap-1">
//                                                             <button className="m-1" onClick={() => copyToClipboard('Your generated text here')}>
//                                                                 <img className="h-5 w-5" src="/icons/copy.png" alt="Copy" />
//                                                             </button>
//                                                             <button className="m-1" onClick={() => setShowGeneratedText(!showGeneratedText)}>
//                                                                 {showGeneratedText ? (
//                                                                     <img className="h-5 w-5" src="/icons/up.png" alt="Hide" />
//                                                                 ) : (
//                                                                     <img className="h-5 w-5" src="/icons/down.png" alt="Show" />
//                                                                 )}
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                     {showGeneratedText && <p className="small">Your generated text here</p>}
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     )}
//                                 </div>
//                             ) : (
//                                 <div className="flex flex-col items-center justify-center">
//                                     <div>
//                                         <div
//                                             className="inline-block text-black h-10 w-10 mx-1 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
//                                             role="status"
//                                         >
//                                             <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
//                                                 Loading...
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <h1 className="font-bold text-lg"> Processing Data </h1>
//                                     <h4 className="text-gray-400">Please be patient as we are generating the Data. This may take some time</h4>
//                                 </div>
//                             )}
//                         </>
//                     )}
//                 </div>
//             </div>
//             <div className="my-2">{isOpen && <RightDrawer isOpen={isOpen} onClose={toggleModal} />}</div>
//             <form onSubmit={handleSubmit}>
//                 <textarea
//                     ref={textareaRef}
//                     value={text}
//                     onChange={handleTextareaChange}
//                     placeholder="Type your text here..."
//                     className="border rounded p-2 w-full resize-none"
//                 />
//                 <button type="submit" className="bg-blue-500 text-white p-2 rounded mt-2">Submit</button>
//             </form>
//             <div className="flex justify-between mt-4">
//                 <button onClick={() => handleClick('generatePdf')} className="bg-green-500 text-white p-2 rounded">Generate PDF</button>
//                 <button onClick={handleClickBetweenLoadingAndChat} className="bg-yellow-500 text-white p-2 rounded">Toggle Loading</button>
//                 <button onClick={toggleModal} className="bg-red-500 text-white p-2 rounded">Toggle Modal</button>
//             </div>
//         </>
//     );
// }

const RightDrawer = ({ isOpen, onClose }) => {
    const [selectedButtons, setSelectedButtons] = useState([])

    const toggleButtonSelection = (index) => {
        if (selectedButtons.includes(index)) {
            setSelectedButtons(selectedButtons.filter((item) => item !== index))
        } else {
            setSelectedButtons([...selectedButtons, index])
        }
    }

    const buttons = Array.from({ length: 10 }, (_, index) => (
        <button
            key={index}
            className={`flex w-full py-2 px-4 mb-2 justify-between mt-1  border rounded-md ${
                selectedButtons.includes(index) ? 'bg-blue-300 text-white' : 'bg-white text-black'
            }`}
            onClick={() => toggleButtonSelection(index)}
        >
            <p className="big">Button {index + 1} </p>
            {selectedButtons.includes(index) && <p className="text-blue-700 big">Selected</p>}
        </button>
    ))
    return (
        <>
            {/* Dimmed background */}
            {isOpen && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-gray-900 opacity-50 z-50"
                    onClick={() => {
                        onClose(!isOpen)
                    }}
                ></div>
            )}

            {/* Modal */}
            {isOpen && (
                <div className="fixed top-0 right-0 h-full w-1/4 rounded z-50 outline-none focus:outline-none bg-white shadow-lg border-gray-300">
                    <div className="p-4">
                        <div className="flex justify-between border-b m-2">
                            <h2 className="big font-bold mb-4 py-3 ">Sections</h2>
                            <button
                                className="rounded big"
                                onClick={() => {
                                    onClose(!isOpen)
                                }}
                            >
                                X Close
                            </button>
                        </div>

                        {buttons}
                    </div>
                </div>
            )}
        </>
    )
}
