import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPdfStatus, setData } from '../../../app/features/smartChatSlice';

const SmartChat = () => {
  const dispatch = useDispatch();
  const { pdfStatus, data } = useSelector((state) => state.smartChat);

  const [activeButtonLoc, setActiveButton] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [syncWithRedux, setSyncWithRedux] = useState(true);

  const containerRef = useRef(null);

  const handleClick = (buttonId) => {
    setActiveButton((prevActiveButton) => (prevActiveButton === buttonId ? null : buttonId));

    if (buttonId === 'generatePdf') {
      dispatch(setPdfStatus('generating'));

      // Simulate PDF generation time (example: 3 seconds)
      setTimeout(() => {
        dispatch(setPdfStatus('downloaded'));
      }, 3000);
    }
  };

  const handleControl = () => {
    if (inputValue){
    dispatch(setPdfStatus('ready'));
    const updatedData = [...data];
    updatedData.push([inputValue, '']);
    dispatch(setData(updatedData));
    setTimeout(() => {
      const updatedDataWithDelay = [...updatedData];
      updatedDataWithDelay.pop();
      dispatch(setData(updatedDataWithDelay));
      const finaldata = [...data]
      finaldata.push([inputValue, 'lorere Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique reprehenderit voluptatum tempora molestiae! Optio labore odio possimus id, quasi eligendi in dolor eius eaque! Praesentium consectetur obcaecati architecto veniam nesciunt.']);
      scrollToBottom();
      dispatch(setData(finaldata));
    }, 1000);}
    else{}
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);



  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // alert('Text copied to clipboard')
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

    const { presets } = useSelector((state) => state.smartChattask);
    // const [syncWithRedux, setSyncWithRedux] = useState(true);
    useEffect(() => {
        
            const selectedPreset = presets.find((preset) => preset.selected);
            if (selectedPreset) {
              setInputValue(selectedPreset.textField);
            } else {
              setInputValue('How can I help you?');
            }
        
    }, [presets]);

  return (
    <>
      <div style={{ height: '54.5vh' }} className="flex flex-col w-full justify-center items-center ">
        <div className="h-[50px]"></div>
        <div
          style={{ height: '45vh' }}
          className="mt-1  items-center h-full overflow-y-auto shadow-md-inner"
          ref={containerRef}
        >
          {pdfStatus === 'generating' ? (
            <>
              <div className="h-full    flex flex-col items-center justify-center  ">
                <div>
                  <div
                    className="inline-block text-black h-10 w-10 mx-1 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      Loading...
                    </span>
                  </div>
                </div>
                <h1 className="font-bold text-lg"> Generating PDF </h1>
                <h4 className="text-gray-400">Please be patient as we are generating the</h4>
                <h4 className="text-gray-400">PDF. This may take some time</h4>
              </div>
            </>
          ) : pdfStatus === 'downloaded' ? (
            <>
              <div className="flex h-full flex-col items-center justify-center">
                <div className="h-10 w-10 mb-1 "></div>
                <h1 className="font-bold text-lg"> PDF Generated Successfully</h1>
                <h4 className="text-gray-400">You can download the pdf from the</h4>
                <h4 className="text-gray-400">'Download PDF' button below</h4>
              </div>
            </>
          ) : (
            <>
              {data.map(([item1, item2], index) => (
                <React.Fragment key={index}>
                  <div className="w-full overflow-auto ">
                    <div className="px-2 mx-3 my-2 overflow-auto border rounded-lg bg-white">
                      <div className="flex justify-between my-2">
                        <div className="flex items-center gap-1">
                          <button className="bg-gray-100 border w-10  p-2 m-2 rounded-lg">
                            <img className="w-6" src="/icons/user.png" alt="logo" />
                          </button>
                          <p className="small">{item1}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full overflow-auto">
                    <div className="px-2 mx-3 overflow-auto border rounded-lg bg-blue-50">
                      <div className="flex flex-col justify-between my-2">
                        <div className="flex items-center gap-1 ">
                          <button className="bg-gray-100 border p-2 m-2 w-10 rounded-lg">
                            <img className="w-6" src="/icons/ai.png" alt="logo" />
                          </button>
                          <p className="small">{item2}</p>
                        </div>
                        <div>
                          <button className=" m-2" onClick={() => copyToClipboard(item2)}>
                            <img
                              className="h-5 w-5"
                              src="/icons/copy.png"
                              alt="Copy to clipboard"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="" style={{ height: '10vh' }}>
        <div className=" flex justify-center items-center">
          {/* generate buttons set */}
          <div className=" ">
            <div className="mt-auto flex p-4">
              {pdfStatus === 'generating' ? (
                <>
                  <button
                    className="flex text-blue-700 bg-gray-100 justify-center items-center border py-2 px-4 rounded mr-2  big "
                    onClick={() => handleClick('generatePdf')}
                    disabled={pdfStatus === 'generating'}
                  >
                    {/* spinner */}
                    <div>
                      <div
                        className="inline-block h-4 w-4 mx-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </div>
                    </div>
                    <p className="big">Generating PDF</p>
                  </button>
                </>
              ) : pdfStatus === 'downloaded' ? (
                <>
                  <button
                    className="flex bg-blue-700 text-white justify-center items-center border py-2 px-4 rounded mr-2 big  "
                    onClick={() => handleClick('generatePdf')}
                    disabled={pdfStatus === 'generating'}
                  >
                    <img className="h-5 w-5 mx-2" src="/icons/updown.png" alt="logo" />
                    <p className="big">Download PDF</p>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="flex  justify-center items-center border py-2 px-4 rounded mr-2  "
                    onClick={() => handleClick('generatePdf')}
                    disabled={pdfStatus === 'generating'}
                  >
                    <img className="h-5 w-5 mx-2" src="/icons/download.png" alt="logo" />
                    <p className="big">Generate PDF</p>
                  </button>{' '}
                </>
              )}

              <button class="flex justify-center items-center  border py-2 px-4 rounded mr-2 big">
                <img className="h-5 w-5 mx-2" src="/icons/download.png" alt="logo" />
                <p> Generate Excel</p>
              </button>
              <button class="flex justify-center items-center  border py-2 px-4 rounded mr-2 big">
                <img className="h-5 w-5 mx-2" src="/icons/download.png" alt="logo" />
                <p>Bookmark</p>
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col mx-1 py-2 items-center">
            <div className="mx-13 justify-center items-center w-4/5 flex border-2 rounded-lg shadow-md active:border">
              <input
                className="w-full px-5 py-2 focus:outline-none active:no-border small"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="How can I help you?"
              />
              <button
                style={{
                  backgroundImage:
                    'linear-gradient(to right, rgba(123, 97, 255, 1), rgba(82, 60, 196, 1))'
                }}
                className="m-1 py-2 px-1 rounded-md"
                onClick={() => {
                  handleControl();
                  setSyncWithRedux(!syncWithRedux);
                  setInputValue('');
                }}
              >
                <div className="mx-5 my-[2px] text-white flex align-center justify-center ">
                  <img className="h-4 w-4 mr-1" src="/icons/whiteStar.png" alt="Generate" />
                  <p className="ml-1 big">Generate</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartChat;
