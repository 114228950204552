import React, { useState } from 'react'

export default function TutorialModal() {
    const [showModal, setShowModal] = React.useState(false)
    const [activeButton, setActiveButton] = useState(null)

    const handleClick = (buttonId) => {
        setActiveButton((prevActiveButton) => (prevActiveButton === buttonId ? null : buttonId))
    }
    return (
        <>
            <button
                className={`bg-gray-100 m-2 flex hover:bg-blue-100 text-sm py-2 px-2 rounded ${
                    activeButton === 'Tutorials' ? 'bg-blue-100' : ''
                }`}
                onClick={() => {
                    handleClick('Tutorials')
                    setShowModal(true)
                }}
            >
                <img className='h-5 w-5 mr-2' src="/icons/tutorial.png" alt="logo"  />
                <span><p className='big'>Tutorials</p></span>
            </button>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-2 mx-auto max-w-5xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h5 className=" text-gray-600 big">Tutorial</h5>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right big leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 big block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <h1 className="font-bold big">🚀 Welcome to the AI Smart Assistant</h1>
                                    <p className="my-4 text-blueGray-500 small  leading-relaxed">
                                        This application allows you to interact with files in a completely new and
                                        exciting way. Here, you can ask questions about your files and our AI assistant
                                        will provide you with answers based on the content of your files. It's like
                                        having a personal assistant for your data!
                                    </p>
                                    <h1 className="font-bold big">📁 How to Select or Upload Files</h1>
                                    <p className="my-4 text-blueGray-500 small  leading-relaxed">
                                        1. Select Files: On the left panel, you'll see a section called '🤖 Select file
                                        📂'. Here you can select the files you want to interact with. Just check the box
                                        next to the file names and you're all set!
                                    </p>
                                    <p className='small'>
                                        2. Upload Files: Can't see the files you need? No problem, you can upload your
                                        own files. Just go to the '📁 FILES' section and click on 'Upload file'. Once
                                        your file is uploaded, click 'Upload 📎' to add it to the list of available
                                        files.
                                    </p>
                                    <p> 

                                    </p>
                                    <h1 className="mt-4 big font-bold">💬 How to Interact with the Assistant</h1>
                                    <p className="my-4 text-blueGray-500 small  leading-relaxed">
                                    Once you've selected or uploaded your files, you can start asking questions to the assistant. Simply type your question into the chat box at the bottom of the main page and press 'Enter'. Our AI assistant will process your question and provide you with an answer based on the content of your files.
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 big outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                       <p className='small'>Close</p> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}
