import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isGenerated: false,
  isLoading: false,
  stop: false,
  isListVisible: false,
  tasks: [],
};

const mirrorSlice = createSlice({
  name: 'mirror',
  initialState,
  reducers: {
    setIsGenerated: (state, action) => {
      state.isGenerated = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setStop: (state, action) => {
      state.stop = action.payload;
    },
    setIsListVisible: (state, action) => {
      state.isListVisible = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    addTask: (state, action) => {
      state.tasks.push(action.payload);
    },
    removeTask: (state, action) => {
      state.tasks = state.tasks.filter((_, index) => index !== action.payload);
    },
    resetmirrorState: () => initialState,
  },
});

export const {
  setIsGenerated,
  setIsLoading,
  setStop,
  setIsListVisible,
  setTasks,
  addTask,
  removeTask,
  resetmirrorState,
} = mirrorSlice.actions;
export default mirrorSlice.reducer;
