import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pdfStatus: 'ready',
  data: [
    [
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique reprehenderit voluptatum tempora molestiae! Optio labore odio possimus id, quasi eligendi in dolor eius eaque! Praesentium consectetur obcaecati architecto veniam nesciunt.',
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique reprehenderit voluptatum tempora molestiae! Optio labore odio possimus id, quasi eligendi in dolor eius eaque! Praesentium consectetur obcaecati architecto veniam nesciunt.'
    ],
    [
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique reprehenderit voluptatum tempora molestiae! Optio labore odio possimus id, quasi eligendi in dolor eius eaque! Praesentium consectetur obcaecati architecto veniam nesciunt.',
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique reprehenderit voluptatum tempora molestiae! Optio labore odio possimus id, quasi eligendi in dolor eius eaque! Praesentium consectetur obcaecati architecto veniam nesciunt.'
    ],
  ]
};

const smartChatSlice = createSlice({
  name: 'smartChat',
  initialState,
  reducers: {
    setPdfStatus(state, action) {
      state.pdfStatus = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    resetsmartchatState: () => initialState,
  }
});

export const { setPdfStatus, setData, resetsmartchatState } = smartChatSlice.actions;
export default smartChatSlice.reducer;
