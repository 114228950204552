import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedFiles: [],
  qntyFiles: 0,
  showMessageMirror: false,
  showMessageKnowledge: false,
  files: [ // Add files array directly to the state
    'Digital Market Acts-01.pdf',
    'Digital Market Acts-02.pdf',
    'Digital Market Acts-03.pdf'
  ]
};

const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    toggleFileSelection: (state, action) => {
      const index = action.payload;
      if (state.selectedFiles.includes(index)) {
        state.selectedFiles = state.selectedFiles.filter(item => item !== index);
        state.qntyFiles -= 1; // Decrease quantity when deselecting
      } else {
        state.selectedFiles.push(index);
        state.qntyFiles += 1; // Increase quantity when selecting
      }
    },
    setShowMessageMirror: (state, action) => {
      state.showMessageMirror = action.payload;
    },
    setShowMessageKnowledge: (state, action) => {
      state.showMessageKnowledge = action.payload;
    },
    addFile: (state, action) => {
      const fileName = action.payload;
      if (!state.files.includes(fileName)) {
        state.files.push(fileName);
      }
    }
  },
});

export const { toggleFileSelection, setShowMessageMirror, setShowMessageKnowledge, addFile } = fileSlice.actions;
export default fileSlice.reducer;
