import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './features/counterSlice';
import navigationReducer from './features/navigationSlice';
import fileReducer from './features/fileSlice';
import mirrorReducer from './features/mirrorSlice';
import knowledgeReducer from './features/knowledgeSlice';
import smartChatReducer from './features/smartChatSlice';
import smartChattaskReducer from './features/smartchattaskslice';
import knowledgetaskReducer from './features/knowledgetaskslice';
import mirrortaskReducer from './features/mirrortaskslice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    navigation: navigationReducer,
    files: fileReducer,
    mirror: mirrorReducer,
    knowledge: knowledgeReducer,
    smartChat: smartChatReducer,
    smartChattask: smartChattaskReducer,
    knowledgetask: knowledgetaskReducer,
    mirrortask: mirrortaskReducer,
  },
});
