import { Divider, tableCellClasses } from '@mui/material'
import React, { useState } from 'react'
import SortableTh from './SortableTh'

export default function Bookmark() {
    const [details, setDetails] = useState([
        {
            SN0: 0,
            Name: 'Data Extracted from Business Ledger',
            Workspace: 'Knowledge Extractor',
            Time: 'Monday 10 April 2024, 09:10:03'
        },
        {
            SN0: 1,
            Name: 'Report Summarized',
            Workspace: 'Smart Chat',
            Time: 'Tuesday 01 April 2025, 09:10:03'
        },
        {
            SN0: 2,
            Name: 'Patient List Extracted',
            Workspace: 'Mirror',
            Time: 'Wednesday 01 April 2023, 09:10:03'
        },
        {
            SN0: 3,
            Name: 'Data Extracted from Business Ledger',
            Workspace: 'Knowledge Extractor',
            Time: 'Thursday 01 April 2024, 09:10:03'
        },
        {
            SN0: 4,
            Name: 'Report Summarized',
            Workspace: 'Knowledge Extractor',
            Time: 'Friday 01 April 2024, 09:10:03'
        }
    ])
    const [copyOn, setCopyOn] = useState(false)
    const [originalOpen, setOriginalOpen] = useState(false)
    const [aiOpen, setAiOpen] = useState(false)
    const [keyPoints, setKeyPoints] = useState(false)
    const [reports, setReports] = useState(false)
    const [sidebarVisible, setSidebarVisible] = useState(false)
    const [selectedDetails, setSelectedDetails] = useState(null)

    const removeItem = (index) => {
        const row = details[index]

        setDetails(details.filter((item) => item !== row))
    }

    const openSidebar = (index) => {
        setSelectedDetails(details[index])
        setSidebarVisible(true)
    }

    const closeSidebar = () => {
        setSidebarVisible(false)
        setSelectedDetails(null)
    }

    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setCopyOn(true)
                setTimeout(() => {
                    setCopyOn(false)
                }, 2000)
            })
            .catch((err) => {
                console.error('Failed to copy: ', err)
            })
    }

    const textForCopy =
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus magni omnis sit est! Eos expedita omnis sapiente necessitatibus. Corrupti voluptatibus, vero, odit ratione dolorum porro vel ut possimus et obcaecati alias libero asperiores magni accusamus excepturi quaerat magnam harum ducimus consectetur? Officiis soluta magnam, hic velit repudiandae dignissimos vero minima, rem accusamus voluptatibus dolores magni fuga itaque perferendis, a unde impedit deserunt illum? Sed.'
    const chatText =
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique ex repellat cum sunt perspiciatis explicabo enim quasi perferendis magni ducimus?'
    const [sortConfig, setSortConfig] = useState({ key: null, direction: '' })

    const dayToIndex = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7
    }

    const sortedDetails = React.useMemo(() => {
        let sortableDetails = [...details]
        if (sortConfig.key) {
            sortableDetails.sort((a, b) => {
                if (sortConfig.key === 'Time') {
                    const timeA = a[sortConfig.key]
                    const timeB = b[sortConfig.key]

                    const isFullDateA = /\d{2} \w+ \d{4}/.test(timeA)
                    const isFullDateB = /\d{2} \w+ \d{4}/.test(timeB)

                    if (isFullDateA && isFullDateB) {
                        const dateA = new Date(
                            timeA.replace(/(\d{2}) (\w+) (\d{4}), (\d{2}:\d{2}:\d{2})/, '$2 $1, $3 $4')
                        )
                        const dateB = new Date(
                            timeB.replace(/(\d{2}) (\w+) (\d{4}), (\d{2}:\d{2}:\d{2})/, '$2 $1, $3 $4')
                        )

                        if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1
                        if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1
                        return 0
                    } else if (!isFullDateA && !isFullDateB) {
                        const dayNameA = timeA.split(' ')[0]
                        const dayNameB = timeB.split(' ')[0]

                        const dayIndexA = dayToIndex[dayNameA]
                        const dayIndexB = dayToIndex[dayNameB]

                        if (dayIndexA < dayIndexB) return sortConfig.direction === 'ascending' ? -1 : 1
                        if (dayIndexA > dayIndexB) return sortConfig.direction === 'ascending' ? 1 : -1
                        return 0
                    } else {
                        return isFullDateA ? -1 : 1
                    }
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1
                    }
                    return 0
                }
            })
        }
        return sortableDetails
    }, [details, sortConfig])

    return (
        <div className="my-4 ">
            <div className=" flex items-center mx-2 gap-2 my-5 ">
                <img src="/icons/bookmarkdark.png" className="text-black h-6" />
                <h1 className=" heading">Bookmarks</h1>
            </div>
            <div className=" ">
                <div className=" ">
                    <table className="w-full lg:w-full border rounded-xl overflow-hidden">
                        <thead className="small text-gray-400  bg-gray-200  text-left">
                            <tr>
                                <th scope="col" className="big">
                                    S.NO
                                </th>
                                <SortableTh
                                    title="Name"
                                    sortKey="Name"
                                    // onSort={onSort}
                                    sortConfig={sortConfig}
                                    setSortConfig={setSortConfig}
                                />
                                <SortableTh
                                    title="Workspace"
                                    sortKey="Workspace"
                                    // onSort={onSort}
                                    sortConfig={sortConfig}
                                    setSortConfig={setSortConfig}
                                />
                                <SortableTh
                                    title="Date/Time"
                                    sortKey="Time"
                                    // onSort={onSort}
                                    sortConfig={sortConfig}
                                    setSortConfig={setSortConfig}
                                />
                                <th scope="col" className=" py-3 pl-24 big text-left">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedDetails.map((item, index) => (
                                <tr key={index} className={`bg-white text-black  border `}>
                                    <th scope="row" className="gap-1 small font-medium text-gray-900 whitespace-nowrap ">
                                        {index < 9 ? (
                                            <>
                                                <p>0{index + 1}</p>
                                            </>
                                        ) : (
                                            <>
                                                <p>{index + 1}</p>
                                            </>
                                        )}
                                    </th>
                                    <th className="text-left small gap-1">{item.Name}</th>
                                    <td className="gap-1 small">{item.Workspace}</td>
                                    <td className="gap-1 small">{item.Time}</td>
                                    <td className="  small  ">
                                        <div className="flex gap-2">
                                            <button
                                                onClick={() => openSidebar(index)}
                                                className="border pt-1 pr-4 pb-1 pl-4 rounded text-blue-600"
                                            >
                                                See Details
                                            </button>
                                            <button
                                                onClick={() => removeItem(index)}
                                                className="border pt-1 pr-4 pb-1 pl-4 rounded text-black"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {sidebarVisible && (
                <div className="fixed top-0 right-0 w-1/4 h-full bg-white shadow-lg z-50">
                    <div className="ml-3 mr-3 ">
                        <div>
                            {selectedDetails && (
                                <div className="">
                                    <div className="flex justify-between mt-4">
                                        <h1 className=" big mb-2 font-semibold">{selectedDetails.Name}</h1>
                                        <button
                                            onClick={closeSidebar}
                                            className="mb-4 small text-gray-500  flex items-center "
                                        >
                                            <img src={`${process.env.PUBLIC_URL}/icons/x.png`} className="h-3 mr-1" />
                                            Close
                                        </button>
                                    </div>
                                    <div className="small">
                                        <p className="mb-2 ">
                                            Workspace:{' '}
                                            <span className="font-semibold">{selectedDetails.Workspace}</span>
                                        </p>

                                        <p className="mb-5">
                                            Date/Time: <span className="font-semibold">{selectedDetails.Time}</span>
                                        </p>
                                        <Divider />
                                    </div>

                                    <p className="mt-4 big font-semibold">Source</p>

                                    <div className="mt-3 small w-full py-3 px-4 rounded border border-gray-300">
                                        Digital Market Acts-01.pdf...
                                    </div>
                                    <div className=" flex justify-between items-center mt-3">
                                        <strong className="mt-3 small">Output</strong>
                                        {copyOn && (
                                            <div
                                                class="p-2  ml-6 small text-green-800 rounded-lg bg-green-50 "
                                                role="alert"
                                            >
                                                <span class="font-medium">Text Copied</span>
                                            </div>
                                        )}
                                    </div>

                                    {selectedDetails.Workspace === 'Knowledge Extractor' && (
                                        <div>
                                            <div className="w-full h-full border mt-3 border-gray rounded-xl ">
                                                <div className="flex justify-start  items-center gap-2 m-3">
                                                    <div className=" ">
                                                        <img className='w-7' alt='' src={`${process.env.PUBLIC_URL}/icons/user.png`} />
                                                    </div>

                                                    <h2 className="small mx-3 text-gray font-semibold flex justify-start w-full">
                                                        Original Text
                                                    </h2>

                                                    <button onClick={() => copyToClipboard(textForCopy)}>
                                                        <img
                                                        alt=''
                                                            src={`${process.env.PUBLIC_URL}/icons/copy.png`}
                                                            className="h-5 w-7"
                                                        />
                                                    </button>

                                                    <button
                                                        className="h-3 mb-3 flex "
                                                        type="button"
                                                        onClick={() => setOriginalOpen(!originalOpen)}
                                                    >
                                                        {originalOpen ? (
                                                            <img alt='' className='h-6 w-8' src={`${process.env.PUBLIC_URL}/icons/up.png`} />
                                                        ) : (
                                                            <img alt='' className='h-6 w-8' src={`${process.env.PUBLIC_URL}/icons/down.png`} />
                                                        )}
                                                    </button>
                                                </div>
                                                {originalOpen && <p className="m-3 small mt-5">{textForCopy}</p>}
                                            </div>

                                            <div className="w-full h-full border mt-3 border-gray rounded-xl bg-purple-100">
                                                <div className="flex items-center gap-2 m-3">
                                                    <div className=" rounded-full ">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/icons/ai.png`}
                                                            className="w-7 "
                                                        />
                                                    </div>

                                                    <h2 className="small mx-3 text-gray font-semibold w-1/2">
                                                        AI Generated
                                                    </h2>
                                                    <div className="flex justify-end w-full gap-2">
                                                        <button onClick={() => copyToClipboard(textForCopy)}>
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/icons/copy.png`}
                                                                className="h-5 w-5"
                                                                alt=''
                                                            />
                                                        </button>

                                                        <button
                                                            className="h-1 mb-3 "
                                                            type="button"
                                                            onClick={() => setAiOpen(!aiOpen)}
                                                        >
                                                            {aiOpen ? (
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/icons/up.png`}
                                                                    className="h-6"
                                                                    alt=''
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/icons/down.png`}
                                                                    className="h-6"
                                                                    alt=''
                                                                />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                                {aiOpen && <p className="m-3 small mt-5">{textForCopy}</p>}
                                            </div>
                                        </div>
                                    )}

                                    {selectedDetails.Workspace === 'Mirror' && (
                                        <div>
                                            <div className="w-full h-full border mt-3 border-gray rounded-xl bg-purple-100">
                                                <div className="flex items-center   my-1">
                                                  

                                                    <p className="small ml-4 text-gray-600 font-semibold ">
                                                        Specific Key points
                                                    </p>
                                                    <div className="flex justify-end ml-auto my-3">
                                                        <button className='' onClick={() => copyToClipboard(textForCopy)}>
                                                            <img
                                            
                                                                src={`${process.env.PUBLIC_URL}/icons/copy.png`}
                                                                className="h-5 w-5"
                                                                alt=''
                                                            />
                                                        </button>

                                                        <button
                                                            className="h-1 mx-3 "
                                                            type="button"
                                                            onClick={() => setKeyPoints(!keyPoints)}
                                                        >
                                                            {keyPoints ? (
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/icons/up.png`}
                                                                    className="h-6"
                                                                    alt=''
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/icons/down.png`}
                                                                    className="h-6"
                                                                    alt =''


                                                                />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                                {keyPoints && (
                                                    <p className="mb-3 ml-6 mr-6 mt-5 small">{textForCopy}</p>
                                                )}
                                            </div>

                                            {/* second output */}

                                            <div className="w-full h-full border mt-3 border-gray rounded-xl bg-purple-100">
                                                <div className="flex items-center gap-2 m-3">
                                                   

                                                    <h2 className="small ml-4 text-gray-600 font-semibold ">
                                                        Reports
                                                    </h2>
                                                    <div className="flex ml-auto -mx-1 ">
                                                        <button className='' onClick={() => copyToClipboard(textForCopy)}>
                                                            <img
                                            
                                                                src={`${process.env.PUBLIC_URL}/icons/copy.png`}
                                                                className="h-5 w-5"
                                                                alt=''
                                                            />
                                                        </button>

                                                        <button
                                                            className="h-1 mx-3 "
                                                            type="button"
                                                            onClick={() => setReports(!reports)}
                                                        >
                                                            {reports ? (
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/icons/up.png`}
                                                                    className="h-6"
                                                                    alt=''
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/icons/down.png`}
                                                                    className="h-6"
                                                                    alt =''


                                                                />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                                {reports && <p className="mr-6 mb-3 ml-6 mt-5 small">{textForCopy}</p>}
                                            </div>
                                        </div>
                                    )}

                                    {selectedDetails.Workspace === 'Smart Chat' && (
                                        <div>
                                            <div className="w-full h-full my-3 bg-gray border border-gray rounded-xl ">
                                                <div className="m-3 flex  gap-4">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/icons/user_logo.png`}
                                                        className="h-10"
                                                        alt=''
                                                    />

                                                    <p className="small">
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse
                                                        harum nostrum corporis id aut illum architecto, nesciunt placeat
                                                        ducimus dolorum.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="w-full h-full bg-purple-100 border border-gray rounded-xl ">
                                                <div className="m-3 flex  gap-4">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/icons/aig_gen.png`}
                                                        className="h-10"
                                                        alt=''
                                                    />

                                                    <p className="small">{chatText}</p>
                                                </div>
                                                <button
                                                    className="ml-16 mb-1"
                                                    onClick={() => copyToClipboard(chatText)}
                                                >
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/icons/copy.png`}
                                                        className="h-5 ml-1 hover:pointer"
                                                        alt=''
                                                    />
                                                </button>
                                            </div>
                                            <div className="w-full h-full my-3 bg-gray border border-gray rounded-xl ">
                                                <div className="m-3 flex  gap-4">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/icons/user_logo.png`}
                                                        className="h-10"
                                                        alt=''
                                                    />

                                                    <p className="small">
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse
                                                        harum nostrum corporis id aut illum architecto, nesciunt placeat
                                                        ducimus dolorum.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="w-full h-full my-3 bg-purple-100 border border-gray rounded-xl ">
                                                <div className="m-3 flex  gap-4">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/icons/aig_gen.png`}
                                                        className="h-10"
                                                    />

                                                    <p className="tsmall">{chatText}</p>
                                                </div>
                                                <button
                                                    className="ml-16 mb-1"
                                                    onClick={() => copyToClipboard(chatText)}
                                                >
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/icons/copy.png`}
                                                        className="h-5 ml-1 hover:pointer"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
